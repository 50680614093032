var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core';
import { ReservationService } from '../../reservation.service';
import { of, Subject } from 'rxjs';
import { subDays, addDays, isSameDay } from 'date-fns';
import moment from 'moment';
import { ThemeService } from '@app/shell/theme.service';
moment.locale('de');
export class OccupancyIndexComponent {
    constructor(reservationService, authService, themeService) {
        this.reservationService = reservationService;
        this.authService = authService;
        this.themeService = themeService;
        this.userClients = [];
        this.clientReservations = {}; // Reservas por cliente
        this.reservations = [];
        this.currentDate = new Date();
        this.ngUnsubscribe = new Subject();
        this.LAYOUT = {
            XXS: 450,
            XS: 768,
            SM: 992,
            MD: 1200,
            LG: 1600,
            XL: 1920,
            XXL: 2560
        };
        this.layout = this.LAYOUT.MD;
        this.themeService.activeTheme$.subscribe((theme) => {
            this.activeTheme = theme;
        });
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.authService
                .getActiveClient()
                .takeUntil(this.ngUnsubscribe)
                .subscribe((clientData) => { });
            try {
                this.getUserClients();
            }
            catch (error) {
                console.error('Error initializing the component:', error);
            }
        });
    }
    getUserClients() {
        const clientsObservable = this.authService.getClients(false) || of({ clients: [] });
        clientsObservable.subscribe(res => {
            if (res && res.clients) {
                this.userClients = res.clients;
                this.loadReservations();
            }
            else {
                console.warn('No clients were found.');
            }
        }, error => {
            console.error('Error retrieving the clients:', error);
        });
    }
    loadReservations() {
        return __awaiter(this, void 0, void 0, function* () {
            for (const client of this.userClients) {
                yield this.getAllReservations(client.id);
            }
        });
    }
    getAllReservations(clientId) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loadingReservationList = true;
            if (!this.clientReservations[clientId]) {
                this.clientReservations[clientId] = {
                    reservations: [],
                    clientInfo: this.userClients.find(client => client.id === clientId) || {}
                };
            }
            yield this.reservationService
                .getAllReservationsBookByCustomClient(this.currentDate, this.currentDate, clientId)
                .toPromise()
                .then((data) => __awaiter(this, void 0, void 0, function* () {
                if (data) {
                    this.clientReservations[clientId].reservations = data.reservations;
                    yield new Promise(resolve => {
                        resolve();
                    });
                    this.loadingReservationList = false;
                }
                else {
                }
            }));
        });
    }
    today() {
        this.currentDate = new Date();
        this.changeDate();
    }
    prevDay() {
        this.currentDate = subDays(this.currentDate, 1);
        this.changeDate();
    }
    nextDay() {
        this.currentDate = addDays(this.currentDate, 1);
        this.changeDate();
    }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    changeDate() {
        return __awaiter(this, void 0, void 0, function* () {
            for (const client of this.userClients) {
                this.clientReservations[client.id].reservations = [];
                yield this.getAllReservations(client.id);
            }
        });
    }
    isToday() {
        return isSameDay(new Date(), this.currentDate);
    }
}

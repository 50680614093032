var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventsService } from '../events.service';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { AuthenticationService } from '@app/core';
import moment from 'moment';
import { AddEventDatesComponent } from '../add-event-dates/add-event-dates.component';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
export class EditEventComponent {
    constructor(formBuilder, eventsService, snackBar, modalService, activatedRoute, router, authService, translate) {
        this.formBuilder = formBuilder;
        this.eventsService = eventsService;
        this.snackBar = snackBar;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.authService = authService;
        this.translate = translate;
        this.passNewEvent = new EventEmitter();
        this.updateEvent = new EventEmitter();
        this.cancelEvent = new EventEmitter();
        this.rows = [];
        this.expanded = {};
        this.eventTickets = [];
        this.currZoom = 15;
        this.ngUnsubscribe = new Subject();
        this.isMapShow = false;
        this.loader = true;
        this.activatedRoute.params.subscribe((parameter) => __awaiter(this, void 0, void 0, function* () {
            this.event_id = parameter.id;
            const [eventData, allPassedDates, allTickets, allActiveTicketDates, allArchiveTicketDates] = yield Promise.all([
                this.eventsService.getSingleEvent(this.event_id, 'active').toPromise(),
                this.eventsService.getSingleEvent(this.event_id, 'archive').toPromise(),
                this.eventsService.getAllTickets(this.event_id).toPromise(),
                this.eventsService.getAllDateTickets('active', this.event_id).toPromise(),
                this.eventsService.getAllDateTickets('archive', this.event_id).toPromise()
            ]);
            this.editEventData = { event: eventData, type: 'active' };
            localStorage.setItem(`tickets`, JSON.stringify(allTickets));
            localStorage.setItem(`ticketDates`, JSON.stringify(allActiveTicketDates));
            localStorage.setItem(`archiveTicketDates`, JSON.stringify(allArchiveTicketDates));
            localStorage.setItem(`eventDates`, JSON.stringify(eventData['eventDates']));
            localStorage.setItem('passedEventDates', JSON.stringify(allPassedDates['eventDates']));
            this.ngOnInit();
        }));
        this.eventFormGroup = this.formBuilder.group({
            name: ['', Validators.required],
            description: [''],
            venue: ['', Validators.required],
            file: [''],
            tag: [''],
            deletePhoto: [false]
        });
        this.loadTranslation();
        this.translate.onLangChange.subscribe((event) => {
            this.loadTranslation();
        });
    }
    get formData() {
        return this.eventFormGroup.get('deliveryTimes');
    }
    toggleExpandRow(row) {
        this.tableMobile.rowDetail.toggleExpandRow(row);
    }
    toggleExpandRow2(row) {
        this.PassDates.rowDetail.toggleExpandRow(row);
    }
    toggleExpandRow3(row) {
        this.PassDatesMobile.rowDetail.toggleExpandRow(row);
    }
    onDetailToggle(event) { }
    ngOnInit() {
        /*
        this.menucardService.progressSource.subscribe((progress: number) => {
          console.log('progress', progress);
          this.progress = progress;
        });
        */
        this.times = [];
        this.locationArray = [];
        this.isList = false;
        this.timer = this.timeoutVal = 500;
        this.message = '';
        this.defaultTime = moment().format('HH:mm');
        this.getTimes();
        this.isTicketView = false;
        if (this.editEventData) {
            const { event, type } = this.editEventData;
            this.tags = event && event.tags ? event.tags.split(',') : [];
            this.type = type;
            this.isGoogleResp = null;
            this.eventFormGroup.get('name').setValue(event.name);
            this.eventFormGroup.get('description').setValue(event.description);
            this.eventFormGroup.get('venue').setValue(event.venue);
            if (event.latitude && event.longitude) {
                this.isMapShow = true;
                this.latitude = event.latitude;
                this.longitude = event.longitude;
            }
            else {
                this.isMapShow = false;
            }
            if (event.photoFile) {
                this.fileName = event.photoFile;
                this.imageUrl = `http://cdn.gastroguide.de/${event.photoFile}`;
            }
            const eventDates = JSON.parse(localStorage.getItem(`eventDates`));
            const ticketDates = JSON.parse(localStorage.getItem(`ticketDates`));
            ticketDates.forEach((eachData) => {
                eventDates.forEach((eachEventDate) => {
                    if (eachData.id === eachEventDate.id) {
                        eachEventDate['ticketDates'] = eachData['ticketDates'];
                    }
                });
            });
            localStorage.setItem(`eventDates`, JSON.stringify(eventDates));
            const arcchiveEventDates = JSON.parse(localStorage.getItem('passedEventDates'));
            const archiveTicketDates = JSON.parse(localStorage.getItem(`archiveTicketDates`));
            archiveTicketDates.forEach((eachData) => {
                arcchiveEventDates.forEach((eachEventDate) => {
                    if (eachData.id === eachEventDate.id) {
                        eachEventDate['ticketDates'] = eachData['ticketDates'];
                    }
                });
            });
            localStorage.setItem(`passedEventDates`, JSON.stringify(arcchiveEventDates));
            this.passedEventDates = JSON.parse(localStorage.getItem('passedEventDates'));
            this.showDates = { event: this.editEventData.event, type: this.editEventData.type };
            this.eventTickets = JSON.parse(localStorage.getItem(`tickets`));
            this.loader = false;
        }
    }
    loadTranslation() {
        this.updateTicketSuccessMessage = this.translate.instant('Events&Tickets.Tickets.UpdateTicketSuccessMessage');
        this.addTicketSuccessMessage = this.translate.instant('Events&Tickets.Tickets.AddTicketSuccessMessage');
        this.deleteEventSuccessMessage = this.translate.instant('Events&Tickets.EditEvent.DeleteEventSuccessMessage');
        this.deleteEventTitle = this.translate.instant('Events&Tickets.EditEvent.DeleteEventTitle');
        this.deleteEventMessage = this.translate.instant('Events&Tickets.EditEvent.DeleteEventMessage');
        this.formInvalid = this.translate.instant('Events&Tickets.Common.FormInvalid');
        this.updateEventSuccessMessage = this.translate.instant('Events&Tickets.EditEvent.UpdateEventSuccessMessage');
        this.deleteModelButtonText = this.translate.instant('Common.Delete');
        this.removeTicketTitle = this.translate.instant('Events&Tickets.Tickets.RemoveTicketTitle');
        this.removeTicketMessage = this.translate.instant('Events&Tickets.Tickets.RemoveTicketMessage');
        this.eventDateSuccessMessage = this.translate.instant('Events&Tickets.EventDates.EventDateAddSuccess');
        this.deleteTicketSuccessMessage = this.translate.instant('Events&Tickets.Tickets.DeleteTicketSuccessMessage');
    }
    dropEvent(event) {
        return __awaiter(this, void 0, void 0, function* () {
            yield moveItemInArray(this.eventTickets, event.previousIndex, event.currentIndex);
            this.eventsService
                .setOrder(this.eventTickets, this.event_id)
                .takeUntil(this.ngUnsubscribe)
                .subscribe((receivedEntry) => {
                this.snackBar.open('Reihenfolge wurde erfolgreich gespeichert', '', {
                    duration: 2000,
                    panelClass: ['snackbar-success']
                });
            });
        });
    }
    dropItem(event) {
        return __awaiter(this, void 0, void 0, function* () { });
    }
    save() {
        this.loader = true;
        if (!this.eventFormGroup.valid) {
            this.loader = false;
            this.snackBar.open(this.formInvalid, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
            return;
        }
        const formData = this.eventFormGroup.value;
        formData['tags'] = this.tags && this.tags.length ? this.tags.toString() : null;
        Object.assign(formData, {
            latitude: this.isGoogleResp ? this.isGoogleResp.latitude : this.editEventData.event.latitude,
            longitude: this.isGoogleResp ? this.isGoogleResp.longitude : this.editEventData.event.longitude
        });
        if (this.isGoogleResp && this.isGoogleResp.isGoogleResp) {
            this.eventsService.setLocation(this.isGoogleResp).subscribe();
        }
        this.error = null;
        this.eventsService
            .saveEvent(formData, this.editEventData.event.id)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((response) => {
            this.snackBar.open(this.updateEventSuccessMessage, '', {
                duration: 2000,
                panelClass: ['snackbar-success']
            });
            this.loader = false;
            this.eventFormGroup.markAsPristine();
            Object.assign(this.editEventData.event, response);
        }, err => {
            this.loader = false;
            this.error = err.error.msg;
        });
    }
    cancel() {
        this.router.navigate([`client/${this.authService.activeClientId}/events/all`]);
    }
    deleteEvent() {
        const modalRef = this.modalService.open(ConfirmModalComponent);
        modalRef.componentInstance.title = this.deleteEventTitle;
        modalRef.componentInstance.message = this.deleteEventMessage;
        modalRef.componentInstance.showInfo = false;
        modalRef.componentInstance.buttonText = this.deleteModelButtonText;
        modalRef.result.then((result) => {
            if (result === 'ok') {
                this.eventsService.removeEvent(this.editEventData.event.id).subscribe((event) => {
                    //this.activeEventList = this.activeEventList.filter((event: any) => event.id !== eventId);
                    this.snackBar.open(this.deleteEventSuccessMessage, '', {
                        duration: 2000,
                        panelClass: ['snackbar-success']
                    });
                    this.cancel();
                }, (err) => {
                    this.snackBar.open(err.error.msg, '', {
                        duration: 2000,
                        panelClass: ['snackbar-error']
                    });
                });
            }
        }, () => { });
    }
    onChange(file) {
        if (file) {
            console.log(file);
            const img = new Image();
            img.src = window.URL.createObjectURL(file);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            img.onload = event => {
                const width = img.naturalWidth;
                const height = img.naturalHeight;
                if (width < 800 && height < 200) {
                    this.snackBar.open('Foto muss mindestens 800px breit und 200px hoch sein', '', {
                        duration: 2000,
                        panelClass: ['snackbar-error']
                    });
                    return;
                }
                const fileTypes = ['image/jpeg', 'image/gif', 'image/jpg', 'image/png'];
                if (!fileTypes.includes(file.type)) {
                    this.snackBar.open('Nicht unterstütztes Dateiformat. Nur jpg, gif und png', '', {
                        duration: 2000,
                        panelClass: ['snackbar-error']
                    });
                    return;
                }
                this.fileName = file.name;
                this.eventFormGroup.get('file').setValue(file);
                this.eventFormGroup.controls['file'].markAsDirty();
                this.imageUrl = reader.result;
            };
        }
    }
    removeFile() {
        this.fileName = null;
        this.eventFormGroup.get('file').setValue('');
        this.eventFormGroup.controls['file'].markAsDirty();
        this.eventFormGroup.get('deletePhoto').setValue(true);
        this.imageUrl = null;
    }
    getTimes() {
        let tStart = 0;
        const interval = 15;
        for (let i = 0; tStart < 24 * 60; i++) {
            const hh = Math.floor(tStart / 60); // getting hours of day in 0-24 format
            const mm = tStart % 60; // getting minutes of the hour in 0-55 format
            const timeObj = {
                time: ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2),
                isClosed: true
            };
            this.times.push(timeObj);
            // console.log('get times');
            const hhNext = Math.floor((tStart + interval) / 60); // getting hours of day in 0-24 format
            const mmNext = (tStart + interval) % 60; // getting minutes of the hour in 0-55 format
            const dateObjNext = new Date();
            dateObjNext.setHours(hhNext);
            dateObjNext.setMinutes(mmNext);
            const dateObj = new Date();
            dateObj.setHours(hh);
            dateObj.setMinutes(mm);
            const defaultTimeDateObj = new Date();
            const defaultTimeSplit = this.defaultTime.split(':');
            defaultTimeDateObj.setHours(+defaultTimeSplit[0]);
            defaultTimeDateObj.setMinutes(+defaultTimeSplit[1]);
            if (dateObj < defaultTimeDateObj && dateObjNext > defaultTimeDateObj) {
                const defaultTimeObj = {
                    time: this.defaultTime,
                    isClosed: false
                };
                this.times.push(defaultTimeObj);
            }
            tStart = tStart + interval;
        }
        // console.log('times => ', this.times);
    }
    addEventTicket(eventId) {
        this.isTicketView = true;
        this.ticketInfo = {
            isEditTicket: false,
            eventId
        };
    }
    addTicketResp(resp) {
        if (resp) {
            this.eventTickets.push(resp);
            localStorage.setItem(`tickets`, JSON.stringify(this.eventTickets));
            this.isTicketView = false;
            this.snackBar.open(this.addTicketSuccessMessage, '', {
                duration: 2000,
                panelClass: ['snackbar-success']
            });
        }
    }
    editTicket(ticketData) {
        return __awaiter(this, void 0, void 0, function* () {
            ticketData['eventId'] = this.editEventData.event.id;
            const [subCategories, allOption] = yield Promise.all([
                this.eventsService.getAllSubCategory(ticketData).toPromise(),
                this.eventsService.getAllOptions(ticketData).toPromise()
            ]);
            const ticketDetails = {
                ticketData,
                subCategories,
                allOption
            };
            this.ticketInfo = {
                isEditTicket: true,
                ticketDetails
            };
            this.isTicketView = true;
        });
    }
    updateTicketResp(resp) {
        this.eventTickets.forEach((ticket) => {
            if (ticket.id === resp.id) {
                Object.assign(ticket, resp);
            }
        });
        this.isTicketView = false;
        localStorage.setItem(`tickets`, JSON.stringify(this.eventTickets));
        const eventDates = JSON.parse(localStorage.getItem(`eventDates`));
        eventDates.forEach((eachDate) => {
            if (eachDate && Object.prototype.hasOwnProperty.call(eachDate, 'ticketDates')) {
                const ticketDatesArray = eachDate.ticketDates;
                ticketDatesArray.forEach((eachTicketDate) => {
                    if (eachTicketDate.tickets.id === resp.id) {
                        Object.assign(eachTicketDate.tickets, resp);
                    }
                });
                // eachDate.ticketDates = eachDate.ticketDates.filter((ticket: any) => ticket.ticketId !== ticketData.id);
            }
        });
        localStorage.setItem(`eventDates`, JSON.stringify(eventDates));
        this.snackBar.open(this.updateTicketSuccessMessage, '', {
            duration: 2000,
            panelClass: ['snackbar-success']
        });
    }
    cancelAction() {
        this.isTicketView = false;
    }
    removeEventTicket(ticketData) {
        const modalRef = this.modalService.open(ConfirmModalComponent);
        modalRef.componentInstance.title = this.removeTicketTitle;
        modalRef.componentInstance.message = this.removeTicketMessage;
        modalRef.componentInstance.showInfo = false;
        modalRef.componentInstance.buttonText = this.deleteModelButtonText;
        modalRef.result.then((result) => {
            if (result === 'ok') {
                this.eventsService
                    .removeTicket(ticketData)
                    .takeUntil(this.ngUnsubscribe)
                    .subscribe((response) => {
                    this.eventTickets = this.eventTickets.filter((ticket) => ticket.id !== ticketData.id);
                    localStorage.setItem(`tickets`, JSON.stringify(this.eventTickets));
                    const eventDates = JSON.parse(localStorage.getItem(`eventDates`));
                    eventDates.forEach((eachDate) => {
                        if (eachDate && Object.prototype.hasOwnProperty.call(eachDate, 'ticketDates')) {
                            eachDate.ticketDates = eachDate.ticketDates.filter((ticket) => ticket.ticketId !== ticketData.id);
                        }
                    });
                    localStorage.setItem(`eventDates`, JSON.stringify(eventDates));
                    this.snackBar.open(this.deleteTicketSuccessMessage, '', {
                        duration: 2000,
                        panelClass: ['snackbar-success']
                    });
                    // const eventDatesArray = [ ...this.editEventData.event['eventDates'] ];
                    // eventDatesArray.forEach((each: any) => {
                    //   each['ticketDates'] = each['ticketDates'].filter((ticket: any) => ticket.ticketId !== ticketData.id);
                    // });
                    // this.editEventData.event['eventDates'] = eventDatesArray;
                    // this.editEventData.event['tickets'] = this.eventTickets;
                    this.showDates = { event: this.editEventData.event, type: this.editEventData.type };
                }, err => {
                    this.error = err.error.msg;
                });
            }
        }, () => { });
    }
    assignTicketResp(resp) {
        // const { data, event } = resp;
        // event['eventDates'].forEach((each: any) => {
        //   if (each.id === data.dateId) {
        //     if (Object.prototype.hasOwnProperty.call(each, 'ticketDates')) {
        //       each['ticketDates'].push(data);
        //     } else {
        //       each['ticketDates'] = [data];
        //     }
        //   }
        // });
        // this.showDates = { event: this.editEventData.event, type: this.editEventData.type };
    }
    addTag() {
        const tag = this.eventFormGroup.value.tag;
        this.eventFormGroup.controls['tag'].reset();
        if (tag && this.tags.indexOf(tag) < 0) {
            this.eventFormGroup.controls['tag'].markAsDirty();
            this.tags.push(tag);
        }
    }
    deleteTag(index) {
        this.eventFormGroup.controls['tag'].markAsDirty();
        this.tags = this.tags.filter((tag, i) => i !== index);
    }
    checkForTyping() {
        window.clearTimeout(this.timer);
        this.isList = false;
        this.isGoogleResp = null;
    }
    fetchLocation() {
        window.clearTimeout(this.timer);
        const text = this.eventFormGroup.value.venue;
        if (text) {
            this.timer = window.setTimeout(() => {
                this.eventsService.getLocations(text).subscribe(locations => {
                    if (locations && locations.length) {
                        this.isList = true;
                        this.locationArray = locations;
                    }
                    else {
                        this.isList = true;
                        this.locationArray = [];
                        this.message = 'No Data Found';
                        this.eventsService.getGoogleLocationData(text).subscribe(response => {
                            const { results } = response;
                            const data = {
                                address: results[0].formatted_address,
                                latitude: results[0].geometry.location.lat,
                                longitude: results[0].geometry.location.lng,
                                isGoogleResp: true
                            };
                            this.locationArray.push(data);
                        });
                        // this.locationArray = { address: 'No Data Found' };
                    }
                });
            }, this.timeoutVal);
        }
        else {
            this.isList = false;
        }
    }
    focusOff() {
        this.isList = false;
    }
    selectLocation(itemObject) {
        this.isMapShow = true;
        this.latitude = itemObject.latitude;
        this.longitude = itemObject.longitude;
        this.isGoogleResp = itemObject;
        this.eventFormGroup.get('venue').setValue(itemObject.address);
        this.isList = false;
    }
    removeEventArchiveDate(date) {
        const modalRef = this.modalService.open(ConfirmModalComponent);
        modalRef.componentInstance.title = 'Archivierten Termin löschen?';
        modalRef.componentInstance.message = `Möchten Sie diesen Termin wirklich löschen?`;
        modalRef.componentInstance.showInfo = false;
        modalRef.componentInstance.buttonText = this.deleteModelButtonText;
        modalRef.result.then((result) => {
            if (result === 'ok') {
                this.eventsService.removeEventDate(date.id, date.eventId).subscribe((data) => {
                    this.snackBar.open('Termin erfolgreich gelöscht.t', '', {
                        duration: 2000,
                        panelClass: ['snackbar-success']
                    });
                    this.passedEventDates = this.passedEventDates.filter((passedDate) => passedDate.id !== date.id);
                    localStorage.setItem('passedEventDates', JSON.stringify(this.passedEventDates));
                }, (err) => {
                    this.snackBar.open(err.error.msg, '', {
                        duration: 2000,
                        panelClass: ['snackbar-error']
                    });
                });
            }
        }, () => { });
    }
    addEventDates(event) {
        const modalRef = this.modalService.open(AddEventDatesComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.eventData = Object.assign({}, event);
        modalRef.componentInstance.passNewEventDate.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry) => {
            const snackBarRef = this.snackBar.open(this.eventDateSuccessMessage, 'Ok', {
                duration: 3000,
                panelClass: ['snackbar-success']
            });
            const eventDates = JSON.parse(localStorage.getItem(`eventDates`));
            eventDates.push(receivedEntry);
            localStorage.setItem(`eventDates`, JSON.stringify(eventDates));
            this.showDates = { event: this.editEventData.event, type: this.editEventData.type };
            modalRef.close();
        });
    }
    getPreview(ticketData, dateData) {
        ticketData.loader = true;
        this.eventsService.getPreviewData(ticketData.ticketId, dateData.id, ticketData.eventId).subscribe((previewData) => {
            const blobdata = new Blob([new Uint8Array(previewData.data)], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blobdata);
            delete ticketData['loader'];
            window.open(fileURL, '_blank');
        }, (err) => {
            console.log('err => ', err);
            this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
        });
    }
    loaderInfo(event) {
        this.loader = event;
    }
    mapClicked(event) {
        console.log(event);
        this.latitude = event['coords']['lat'];
        this.longitude = event['coords']['lng'];
        this.isGoogleResp = {
            latitude: this.latitude,
            longitude: this.longitude
        };
        this.getCurrentLocation(this.latitude, this.longitude);
    }
    getCurrentLocation(lat, lng) {
        this.eventsService.getlocationlatlng(lat, lng).subscribe(response => {
            const { results } = response;
            this.eventFormGroup.get('venue').setValue(results[0].formatted_address);
        });
    }
}

<breadcrumb></breadcrumb>
<div class="content-box">
  <div class="row">
    <div class="col-sm-12 col-md-12">
      <div class="element-wrapper">
        <ng-template>
          <div class="element-actions" *ngIf="layout >= LAYOUT.SM"></div>
          <h6 class="element-header">
            Reservierungsbuch - Belegungsplanübersicht
          </h6>
        </ng-template>
        <div #fullscreenEl id="fullscreenElement" class="fullscreen-element">
          <div class="button-bar" #buttonBar fxLayout="row">
            <div
              class="column date-selection"
              fxFlex
              fxFlexOrder="2"
              fxFlexOrder.gt-xs="1"
              fxFlexGrow="2"
              fxLayoutGap="10px"
            >
              <button
                class="btn btn-home-no-padding"
                [ngClass]="activeTheme === 'light-theme' ? 'btn-white' : 'btn-dark'"
                (click)="today()"
              >
                <i class="fas fa-home"></i>
              </button>
              <div class="btn-group">
                <button
                  class="btn btn-margin btn-home-no-padding"
                  [ngClass]="activeTheme === 'light-theme' ? 'btn-white' : 'btn-dark'"
                  (click)="prevDay()"
                >
                  <i class="fas fa-chevron-left"></i>
                </button>
                <input
                  class="btn-home-no-padding"
                  matInput
                  [matDatepicker]="picker"
                  type="text"
                  [(ngModel)]="currentDate"
                  (dateChange)="changeDate()"
                />
                <button
                  class="btn date-btn btn-home-no-padding"
                  [ngClass]="activeTheme === 'light-theme' ? 'btn-white' : 'btn-dark'"
                  (click)="picker.open()"
                >
                  <ng-template [ngIf]="isToday()">Heute</ng-template>
                  <ng-template [ngIf]="!isToday() && layout >= LAYOUT.MD">{{
                    currentDate | dfnsFormat: 'dddd, DD. MMMM YYYY'
                  }}</ng-template>
                  <ng-template [ngIf]="!isToday() && layout < LAYOUT.MD">{{
                    currentDate | dfnsFormat: 'dd, DD. MMM YYYY'
                  }}</ng-template>
                </button>
                <mat-datepicker #picker></mat-datepicker>
                <button
                  class="btn"
                  [ngClass]="activeTheme === 'light-theme' ? 'btn-white' : 'btn-dark'"
                  (click)="nextDay()"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="occupancy-index">
            <div *ngFor="let client of clientReservations | keyvalue" class="client-section">
              <div class="client-info">
                <h3>
                  {{ client.value.clientInfo.name }}
                </h3>
                <a> {{ client.value.clientInfo.zipCode }} {{ client.value.clientInfo.location }}</a>
              </div>
              <div>
                <ul class="reservation-list">
                  <div *ngIf="loadingReservationList">
                    <div class="loading-page">
                      <div class="spinner">
                        <mat-progress-spinner color="primary" mode="indeterminate" diameter="40"></mat-progress-spinner>
                      </div>
                      <h3 class="spinner">Laden...</h3>
                    </div>
                  </div>
                  <div *ngIf="!loadingReservationList">
                    <app-occupancy-per-hour
                      [listOfReservations]="client.value.reservations"
                      [clientId]="client.value.clientInfo.id"
                    >
                    </app-occupancy-per-hour>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

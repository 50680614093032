import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
// import { ReservationService } from '@app/reservation/reservation.service.js';
import { ReservationBookSettings } from '@app/reservation/reservation-settings/reservation-settings.model';
import moment from 'moment';
import { addMinutes } from 'date-fns';
import { Subscription } from 'rxjs';
import { ReservationService } from '@app/reservation/reservation.service';
import { AuthenticationService } from '@app/core';

@Component({
  selector: 'app-occupancy-per-hour',
  templateUrl: './occupancy-per-hour.component.html',
  styleUrls: ['./occupancy-per-hour.component.scss']
})
export class OccupancyPerHourComponent implements OnChanges, OnInit, OnDestroy {
  @Input()
  listOfReservations: any[];
  @Input() clientId?: number;
  reservations: any = [];
  numberOfTables: number = 0;
  numberOfSeats: number = 0;
  isHidden: boolean = false;
  reservationBook: ReservationBookSettings;
  emptyReservations: any[];
  isAutoPlaceOn: boolean;
  hasAllNecessarySettings: boolean = true;
  subscriptions: Subscription[] = [];

  constructor(private reservationService: ReservationService, private authService: AuthenticationService) {}

  ngOnInit(): void {
    const client = this.clientId || this.authService.activeClientId;

    this.subscriptions.push(
      this.reservationService.getReservationBookSettings(client).subscribe((settings: any) => {
        if (!settings.startTime || !settings.endTime || !settings.timeInterval) {
          this.hasAllNecessarySettings = false;
        }
        this.reservationBook = settings;
        this.emptyReservations = this.createArrayOfHoursFromSettings(
          this.reservationBook.startTime,
          this.reservationBook.endTime,
          this.reservationBook.timeInterval
        );
        this.reservations = this.emptyReservations;
        this.fillArrayWithReservations(this.listOfReservations);
      })
    );
    this.subscriptions.push(
      this.authService.getClientResSettings(client).subscribe((settings: any) => {
        if (settings && Object.keys(settings).length > 0) {
          this.isAutoPlaceOn = settings.autoPlace;
          this.reservationService.getTotalNumberOfTablesAndChairs(settings.autoPlace).subscribe((data: any) => {
            this.numberOfSeats = data.numberOfSeats;
            this.numberOfTables = data.numberOfTables;
          });
        }
      })
    );
  }

  ngOnChanges(): void {
    this.fillArrayWithReservations(this.listOfReservations);
  }

  hideTable(): void {
    this.isHidden = !this.isHidden;
  }

  fillArrayWithReservations(reservations: any[]): void {
    if (reservations.length > 0 && this.emptyReservations) {
      const dateOfReservation = reservations[0].startDate.slice(0, 10);
      const tempReservationArray = JSON.parse(JSON.stringify(this.emptyReservations));

      for (let i = 0; i < tempReservationArray.length; i++) {
        const reservationTime = tempReservationArray[i];
        const dateAndTimeToCheckTable = new Date(`${dateOfReservation} ${reservationTime.time}`);
        const reservationsForSelectedTime = reservations.filter(element => {
          const elementStartDate = new Date(element.startDate);
          const elementEndDate = new Date(element.endDate);
          return (
            elementStartDate.getTime() <= dateAndTimeToCheckTable.getTime() &&
            elementEndDate.getTime() > dateAndTimeToCheckTable.getTime() &&
            element.status !== 'canceled' &&
            element.status !== 'noShow'
          );
        });
        reservationsForSelectedTime.forEach(element => {
          const elementStartDate = new Date(element.startDate);
          reservationTime.seatsTaken += element.peopleCount;
          reservationTime.tablesTaken += element.tables ? element.tables.length : 0;

          if (
            elementStartDate.getTime() + this.reservationBook.timeInterval * 60000 >
            dateAndTimeToCheckTable.getTime()
          ) {
            reservationTime.newArriving += element.peopleCount;
          }
        });
      }

      this.reservations = tempReservationArray;
    } else {
      this.reservations = this.emptyReservations;
    }
  }

  createArrayOfHoursFromSettings(startTime: number, endTime: number, interval: number) {
    if (!this.hasAllNecessarySettings) {
      return;
    }
    const start = new Date();
    start.setHours(startTime, 0, 0);
    const end = new Date();
    end.setHours(endTime, 0, 0);
    const emptyReservations: any[] = [];

    for (let currentTime = start.getTime(); currentTime < end.getTime(); ) {
      const time = moment(new Date(currentTime)).format('HH:mm');
      const emptyReservationObject = { time: time, seatsTaken: 0, tablesTaken: 0, newArriving: 0 };
      emptyReservations.push(emptyReservationObject);
      currentTime = addMinutes(currentTime, interval).getTime();
    }
    return emptyReservations;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}

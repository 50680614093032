var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { EventsService } from '../events.service';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { MatSnackBar } from '@angular/material';
import { AddEditSubcategoryComponent } from '../add-edit-subcategory/add-edit-subcategory.component';
import { AddEditOptionComponent } from '../add-edit-option/add-edit-option.component';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
export class AddEditTicketComponent {
    constructor(formBuilder, eventsService, snackBar, modalService, translate) {
        this.formBuilder = formBuilder;
        this.eventsService = eventsService;
        this.snackBar = snackBar;
        this.modalService = modalService;
        this.translate = translate;
        this.addTicketResp = new EventEmitter();
        this.cancelAction = new EventEmitter();
        this.updateTicketResp = new EventEmitter();
        this.allSubCategory = [];
        this.allOptions = [];
        this.ngUnsubscribe = new Subject();
        this.ticketFormGroup = this.formBuilder.group({
            name: ['', Validators.required],
            description: [''],
            additionalText: [''],
            amount: [0],
            isTcktHldrNameCmplsr: [false],
            minimumPeople: [''],
            feeTakeover: [false],
            serviceCharge: 0.0
        });
        this.loadTranslation();
        this.translate.onLangChange.subscribe((event) => {
            this.loadTranslation();
        });
    }
    ngOnInit() {
        window.scroll(-10, -10);
        this.isEditTicket = this.ticketInfo.isEditTicket;
        this.isOption = false;
        if (this.isEditTicket) {
            const { ticketData } = this.ticketInfo.ticketDetails;
            this.ticketFormGroup.get('name').setValue(ticketData.name);
            this.ticketFormGroup.get('description').setValue(ticketData.description);
            this.ticketFormGroup.get('additionalText').setValue(ticketData.additionalText);
            this.ticketFormGroup.get('amount').setValue(ticketData.amount);
            this.ticketFormGroup.get('minimumPeople').setValue(ticketData.minimumPeople);
            this.ticketFormGroup.get('serviceCharge').setValue(ticketData.serviceCharge);
            this.feeTakeover = ticketData.feeTakeover;
            this.isTcktHldrNameCmplsr = ticketData.isTcktHldrNameCmplsr;
            this.allSubCategory = this.ticketInfo.ticketDetails.subCategories;
            this.allSubCategory.forEach((each) => {
                if (each.subCategoryDetails &&
                    (each.subCategoryDetails.length > 0 || Object.keys(each.subCategoryDetails).length > 0)) {
                    each.stayTime = each.subCategoryDetails.stayTime;
                    each.groupSize = each.subCategoryDetails.groupSize;
                }
                each.price = each.price.toFixed(2);
            });
            this.allOptions = this.ticketInfo.ticketDetails.allOption;
            this.allOptions.forEach((each) => {
                each.price = each.price.toFixed(2);
            });
        }
        else {
            this.isTcktHldrNameCmplsr = false;
            this.feeTakeover = false;
            this.allSubCategory = [
                {
                    name: '',
                    description: '',
                    price: 0.0
                }
            ];
            this.allOptions = [
                {
                    name: '',
                    description: '',
                    price: 0.0
                }
            ];
        }
    }
    loadTranslation() {
        this.ticketFormInvalid = this.translate.instant('Events&Tickets.Common.FormInvalid');
        this.minCategoryError = this.translate.instant('Events&Tickets.Categories.MinCategoryError');
        this.updateOptionSuccessMessage = this.translate.instant('Events&Tickets.Option.UpdateOptionSuccessMessage');
        this.somethingWentWrong = this.translate.instant('Common.SomethingWentWrong');
        this.updateCategorySuccessMessage = this.translate.instant('Events&Tickets.Categories.UpdateCategorySuccessMessage');
        this.removeCatTitle = this.translate.instant('Events&Tickets.Categories.RemoveCatTitle');
        this.removeCatMessage = this.translate.instant('Events&Tickets.Categories.RemoveCatMessage');
        this.removeCatSuccessMessage = this.translate.instant('Events&Tickets.Categories.RemoveCatSuccessMessage');
        this.deleteLastCatErrorMessage = this.translate.instant('Events&Tickets.Categories.DeleteLastCatErrorMessage');
        this.removeOptTitle = this.translate.instant('Events&Tickets.Option.RemoveOptTitle');
        this.removeOptMessage = this.translate.instant('Events&Tickets.Option.RemoveOptMessage');
        this.removeOptSuccessMessage = this.translate.instant('Events&Tickets.Option.RemoveOptSuccessMessage');
        this.deleteModelButtonText = this.translate.instant('Common.Delete');
    }
    add() {
        if (!this.ticketFormGroup.valid) {
            this.snackBar.open(this.ticketFormInvalid, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
            return;
        }
        const categories = this.allSubCategory && this.allSubCategory.length
            ? this.allSubCategory.filter((category) => category.name.trim())
            : [];
        const options = this.allOptions && this.allOptions.length ? this.allOptions.filter((option) => option.name.trim()) : [];
        if (categories && categories.length) {
            const ticketData = this.ticketFormGroup.value;
            ticketData['eventId'] = this.ticketInfo.eventId;
            ticketData['subCategoryData'] = categories;
            ticketData['optionData'] = options && options.length ? options : null;
            this.eventsService
                .addTicket(ticketData)
                .takeUntil(this.ngUnsubscribe)
                .subscribe((response) => {
                this.addTicketResp.emit(response);
            }, err => {
                this.error = err.error.msg;
            });
        }
        else {
            this.ifNoCategory = true;
            this.snackBar.open(this.minCategoryError, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
            return;
        }
    }
    save() {
        if (!this.ticketFormGroup.valid) {
            this.snackBar.open(this.ticketFormInvalid, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
            return;
        }
        const categories = this.allSubCategory && this.allSubCategory.length
            ? this.allSubCategory.filter((category) => category.name.trim())
            : [];
        const options = this.allOptions && this.allOptions.length ? this.allOptions.filter((option) => option.name.trim()) : [];
        if (categories && categories.length) {
            categories.forEach((each) => {
                each['ticketId'] = this.ticketInfo.ticketDetails.ticketData.id;
            });
            if (options && options.length) {
                options.forEach((each) => {
                    each['ticketId'] = this.ticketInfo.ticketDetails.ticketData.id;
                });
            }
            const ticketData = this.ticketFormGroup.value;
            ticketData['eventId'] = this.ticketInfo.ticketDetails.ticketData.eventId;
            ticketData['id'] = this.ticketInfo.ticketDetails.ticketData.id;
            ticketData['subCategoryData'] = categories;
            ticketData['optionData'] = options && options.length ? options : null;
            this.eventsService
                .updateTicket(ticketData)
                .takeUntil(this.ngUnsubscribe)
                .subscribe((response) => {
                this.updateTicketResp.emit(ticketData);
            }, err => {
                this.error = err.error.msg;
            });
        }
        else {
            this.snackBar.open(this.minCategoryError, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
            return;
        }
    }
    cancel() {
        this.cancelAction.emit();
    }
    removeCategory(categoryInfo, i) {
        const categoryId = categoryInfo.id;
        const modalRef = this.modalService.open(ConfirmModalComponent);
        modalRef.componentInstance.title = this.removeCatTitle;
        modalRef.componentInstance.message = this.removeCatMessage;
        modalRef.componentInstance.showInfo = false;
        modalRef.componentInstance.buttonText = this.deleteModelButtonText;
        modalRef.result.then((result) => {
            if (result === 'ok') {
                if (categoryId) {
                    if (this.allSubCategory.length > 1) {
                        this.lastDeteleMessage = '';
                        this.eventsService.removeSubCategory(categoryId, this.ticketInfo.ticketDetails.ticketData).subscribe((event) => {
                            this.snackBar.open(this.removeCatSuccessMessage, '', {
                                duration: 2000,
                                panelClass: ['snackbar-success']
                            });
                            this.allSubCategory = this.allSubCategory.filter((category) => category.id !== categoryId);
                        }, (err) => {
                            this.snackBar.open(err.error.msg, '', {
                                duration: 2000,
                                panelClass: ['snackbar-error']
                            });
                        });
                    }
                    else {
                        this.lastDeteleMessage = this.deleteLastCatErrorMessage;
                        setTimeout(() => {
                            this.lastDeteleMessage = '';
                        }, 5000);
                    }
                }
                else {
                    this.allSubCategory = this.allSubCategory.filter((category, index) => index !== i);
                }
            }
        }, () => { });
    }
    removeOption(optionData, i) {
        const optionId = optionData.id;
        const modalRef = this.modalService.open(ConfirmModalComponent);
        modalRef.componentInstance.title = this.removeOptTitle;
        modalRef.componentInstance.message = this.removeOptMessage;
        modalRef.componentInstance.showInfo = false;
        modalRef.componentInstance.buttonText = this.deleteModelButtonText;
        modalRef.result.then((result) => {
            if (result === 'ok') {
                if (optionId) {
                    this.eventsService.removeOption(optionId, this.ticketInfo.ticketDetails.ticketData).subscribe((event) => {
                        this.snackBar.open(this.removeOptSuccessMessage, '', {
                            duration: 2000,
                            panelClass: ['snackbar-success']
                        });
                        this.allOptions = this.allOptions.filter((option) => option.id !== optionId);
                    }, (err) => {
                        this.snackBar.open(this.somethingWentWrong, '', {
                            duration: 2000,
                            panelClass: ['snackbar-error']
                        });
                    });
                }
                else {
                    this.allOptions = this.allOptions.filter((option, index) => index !== i);
                }
            }
        }, () => { });
    }
    addSubCategory(isTicket) {
        let isValid = false;
        this.ifNoCategory = false;
        for (let i = 0; i < this.allSubCategory.length; i++) {
            if (this.allSubCategory[i].name) {
                isValid = true;
            }
        }
        if (!isValid && this.allSubCategory.length) {
            this.snackBar.open(this.minCategoryError, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
            this.ifNoCategory = true;
            return;
        }
        this.allSubCategory.push({
            name: '',
            description: '',
            price: 0.0,
            stayTime: '',
            groupSize: '',
            ticketId: this.isEditTicket ? this.ticketInfo.ticketDetails.ticketData.id : null
        });
    }
    updateSubCategory(subCategoryData) {
        this.categoryInfo = {
            isEditCategory: true,
            ticketInfo: this.ticketInfo.ticketDetails.ticketData,
            subCategoryData
        };
        const modalRef = this.modalService.open(AddEditSubcategoryComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.categoryInfo = Object.assign({}, this.categoryInfo);
        modalRef.componentInstance.updateCategoryResp.takeUntil(this.ngUnsubscribe).subscribe((resp) => {
            this.snackBar.open(this.updateCategorySuccessMessage, 'Ok', {
                duration: 3000,
                panelClass: ['snackbar-success']
            });
            this.eventsService.getAllSubCategory(this.ticketInfo.ticketDetails.ticketData).subscribe((categories) => {
                this.allSubCategory = categories;
                modalRef.close();
            }, (err) => {
                this.snackBar.open(this.somethingWentWrong, '', {
                    duration: 2000,
                    panelClass: ['snackbar-error']
                });
            });
        }, (err) => {
            this.snackBar.open(this.somethingWentWrong, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
        });
    }
    addOption(isTicket) {
        this.allOptions.push({
            name: '',
            description: '',
            price: 0.0
        });
    }
    updateOption(optionData) {
        this.optionInfo = {
            isEditOption: true,
            ticketInfo: this.ticketInfo.ticketDetails.ticketData,
            optionData
        };
        const modalRef = this.modalService.open(AddEditOptionComponent, { windowClass: 'onboarding-modal' });
        modalRef.componentInstance.optionInfo = Object.assign({}, this.optionInfo);
        modalRef.componentInstance.updateOptionResp.takeUntil(this.ngUnsubscribe).subscribe((resp) => {
            this.snackBar.open(this.updateOptionSuccessMessage, 'Ok', {
                duration: 3000,
                panelClass: ['snackbar-success']
            });
            this.eventsService.getAllOptions(this.ticketInfo.ticketDetails.ticketData).subscribe((options) => {
                this.allOptions = options;
                modalRef.close();
            }, (err) => {
                this.snackBar.open(this.somethingWentWrong, '', {
                    duration: 2000,
                    panelClass: ['snackbar-error']
                });
            });
        }, (err) => {
            this.snackBar.open(this.somethingWentWrong, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
            });
        });
    }
    setToTwoDigits(type, index) {
        let price;
        switch (type) {
            case 'subCategory':
                if (this.allSubCategory[index].price) {
                    price = parseFloat(this.allSubCategory[index].price);
                    price = price.toFixed(2);
                    this.allSubCategory[index].price = price;
                }
                break;
            case 'option':
                if (this.allOptions[index].price) {
                    price = parseFloat(this.allOptions[index].price);
                    price = price.toFixed(2);
                    this.allOptions[index].price = price;
                }
                break;
        }
    }
    serviceChargeChanged(event) {
        this.ticketFormGroup.get('serviceCharge').setValue(event);
    }
    dropTicket(event) {
        return __awaiter(this, void 0, void 0, function* () {
            yield moveItemInArray(this.allSubCategory, event.previousIndex, event.currentIndex);
            this.eventsService
                .setSubcatOrder(this.allSubCategory, this.ticketInfo.ticketDetails.ticketData)
                .takeUntil(this.ngUnsubscribe)
                .subscribe((receivedEntry) => {
                this.snackBar.open('Reihenfolge wurde erfolgreich gespeichert', '', {
                    duration: 2000,
                    panelClass: ['snackbar-success']
                });
            });
        });
    }
    dropItem(event) {
        return __awaiter(this, void 0, void 0, function* () { });
    }
}

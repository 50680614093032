<div class="modal-content text-center">
  <button aria-label="Close" class="close" (click)="activeModal.dismiss()" type="button">
    <span class="fas fa-times"></span>
  </button>
  <div class="onboarding-content">
    <h4 class="onboarding-title">
      <ng-template [ngIf]="!editResTimeData">{{
        'ResSettings.AddResSeries.NewReservationTime' | translate
      }}</ng-template>
      <ng-template [ngIf]="editResTimeData && !editResTimeData.blocked">{{
        'ResSettings.AddResSeries.ReservationTime' | translate
      }}</ng-template>
      <ng-template [ngIf]="editResTimeData && editResTimeData.blocked">
        {{ 'ResSettings.AddResSeries.BlockTime' | translate }}</ng-template
      >
    </h4>
    <form [formGroup]="resSeriesFormGroup">
      <div class="row" *ngIf="editResTimeData && !editResTimeData.blocked">
        <div class="col-sm-12">
          <div class="form-group toggle-div">
            {{ 'ResSettings.AddResSeries.CompleteSeriesChange' | translate }}&nbsp;
            <mat-slide-toggle
              style="vertical-align:bottom"
              (change)="changeEditOption()"
              formControlName="dayEditOption"
            >
            </mat-slide-toggle>
            &nbsp;{{ 'ResSettings.AddResSeries.OnlyDayChange' | translate }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'ResSettings.AddResSeries.StartDate' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend" (click)="dateFromPicker.open()">
                <div class="input-group-text">
                  <i class="fas fa-calendar-alt"></i>
                </div>
              </div>
              <input
                class="form-control"
                #dateFromInput
                placeholder=""
                type="text"
                name="alert"
                formControlName="startDate"
                [matDatepicker]="dateFromPicker"
                (focus)="dateFromPicker.open()"
                required
              />
              <mat-datepicker #dateFromPicker (closed)="dateFromInput.blur()"></mat-datepicker>
            </div>
          </div>
        </div>
        <div class="col-sm-6" *ngIf="!editResTimeData || (editResTimeData && !editResTimeData.singleBlockDate)">
          <div class="form-group">
            <label for="">{{ 'ResSettings.AddResSeries.EndDate' | translate }}</label>
            <div class="input-group">
              <div class="input-group-prepend" (click)="dateToPicker.open()">
                <div class="input-group-text">
                  <i class="fas fa-calendar-alt"></i>
                </div>
              </div>
              <input
                class="form-control"
                #dateToInput
                placeholder="Optional"
                type="text"
                name="alert"
                formControlName="endDate"
                [matDatepicker]="dateToPicker"
                (focus)="dateToPicker.open()"
              />
              <span (click)="clearInput()" *ngIf="resSeriesFormGroup.value.endDate" class="clear-input"
                ><i class="fas fa-times"></i
              ></span>
              <mat-datepicker #dateToPicker (closed)="dateToInput.blur()"></mat-datepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="alert alert-danger" *ngIf="resSeriesFormGroup.errors && resSeriesFormGroup.errors.dates">
        {{ resSeriesFormGroup.errors?.dates }}
      </div>
      <div class="row" *ngIf="!editResTimeData || (editResTimeData && !editResTimeData.blocked)">
        <div class="col-sm-6">
          <div class="form-group">
            <label for=""> {{ 'ResSettings.AddResSeries.StartTime' | translate }}</label>
            <ngb-timepicker formControlName="startTime" [meridian]="false"></ngb-timepicker>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="">{{ 'ResSettings.AddResSeries.EndTime' | translate }}</label>
            <ngb-timepicker formControlName="endTime" [meridian]="false"></ngb-timepicker>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!editResTimeData || (editResTimeData && !editResTimeData.blocked)">
        <div class="col-sm-12">
          <div class="form-group">
            <label style="vertical-align: top;">{{ 'ResSettings.AddResSeries.Repetition' | translate }}</label>
            <div>
              <mat-radio-group formControlName="frequency">
                <mat-radio-button value="never">{{ 'ResSettings.AddResSeries.Never' | translate }}</mat-radio-button>
                <mat-radio-button value="daily">{{ 'ResSettings.AddResSeries.Daily' | translate }}</mat-radio-button>
                <mat-radio-button value="weekly">{{
                  'ResSettings.AddResSeries.DayByDay' | translate
                }}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row"
        *ngIf="resSeriesFormGroup.value.frequency === 'weekly' && (!editResTimeData || !editResTimeData.blocked)"
      >
        <div class="col-sm-12">
          <div class="form-group">
            <!-- <button class="btn btn-primary" #selectValue [value]=false (click)="selectAllDays()">
              Select All
            </button> -->
            <span *ngFor="let weekday of weekdays" class="checkbox">
              <label class="days-checkbox">
                <input
                  type="checkbox"
                  name
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="weekday.isChecked"
                  (change)="weekday.isChecked = $event.target.checked"
                />&nbsp; {{ weekday.day }} &nbsp;
              </label>
            </span>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="allShifts && allShifts.length">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="">{{ 'ResSettings.AddResSeries.ReservationTemplate' | translate }}</label>
            <ng-select
              [readonly]="editResTimeData && editResTimeData.blocked"
              [searchable]="false"
              [items]="allShifts"
              [clearable]="false"
              formControlName="shift"
              bindLabel="name"
              bindValue="id"
              required
            >
            </ng-select>
          </div>
        </div>
      </div>
      <button class="btn btn-primary btn-block btn-lg" (click)="add()">
        {{ 'ResSettings.AddResSeries.Save' | translate }}
      </button>
    </form>
  </div>
</div>

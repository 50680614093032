<div *ngIf="loadingTable">
  <div class="loading-page">
    <div class="spinner">
      <mat-progress-spinner color="primary" mode="indeterminate" diameter="40"> </mat-progress-spinner>
    </div>
    <h3 class="spinner">Laden...</h3>
  </div>
</div>

<ng-template #nameSummaryCell let-row="row" let-value="value">
  <div [ngClass]="fsValue" class="table-footer" *ngIf="totalReservations > 0 && !loadingTable">
    Insgesamt
    <strong>{{ totalReservations }} Reservierung<span *ngIf="totalReservations > 1">en</span></strong>
    mit
    <strong>{{ totalGuests === 1 ? 'einem Gast' : totalGuests + ' Gästen' }}</strong>
    <ng-template [ngIf]="shiftDetails && shiftDetails?.length"> ({{ shiftDetails }})</ng-template>
  </div>
</ng-template>

<div *ngIf="!loadingTable && reservationListActive === false" [ngClass]="fsValue" class="ngx-datatable-template">
  <div class="datatable-res-list" *ngIf="!isArchiveList">
    <ngx-datatable
      #dataTable
      class="bootstrap reservations-table expandable"
      [class.no-footer]="!showFooter"
      [columnMode]="'flex'"
      [headerHeight]="35"
      [footerHeight]="50"
      [summaryRow]="showSummary"
      [loadingIndicator]="loading"
      [summaryHeight]="'auto'"
      [rowHeight]="'auto'"
      [virtualization]="false"
      [scrollbarV]="false"
      [scrollbarH]="false"
      [rows]="reservations"
      [limit]="limit"
      [cssClasses]="{
        pagerLeftArrow: 'fas fa-backward',
        pagerRightArrow: 'fas fa-forward',
        pagerPrevious: 'fas fa-step-backward',
        pagerNext: 'fas fa-step-forward'
      }"
      (activate)="onRowActivate($event)"
      [sorts]="[{ prop: 'reservedFor', dir: 'asc' }]"
      [messages]="{ emptyMessage: emptyMessage, total: ' insgesamt' }"
      (sort)="onSort($event)"
      [rowClass]="getRowClass"
    >
      <ngx-datatable-column
        [sortable]="true"
        [canAutoResize]="false"
        [resizeable]="false"
        prop="reservedFor"
        name="Zeit"
        [width]="80"
        [summaryTemplate]="nameSummaryCell"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div style="display: flex; align-items: center;">
            <div>
              <b>
                {{ row.startDate | dfnsFormat: 'HH:mm' }}
                <sup
                  *ngIf="date && !isSameDayHelper(row.startDate) && !loading"
                  matTooltip="Reservierung fällt auf den nächsten Tag"
                  >+1</sup
                >
              </b>
              <br />
              {{ row.endDate | dfnsFormat: 'HH:mm' }}
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [sortable]="true" [resizeable]="false" prop="guestData.name" name="Name" [flexGrow]="2">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <ng-template [ngIf]="row.guestData">
            <div style="display: flex; align-items: center;">
              <span
                *ngIf="row && row.shift"
                class="status-pill smaller"
                [matTooltip]="row.shift.name"
                [ngStyle]="{ 'background-color': row.shift ? row.shift.color : '#fff' }"
              ></span>

              <span [ngClass]="{ 'line-strike': row?.status === 'canceled' }" *ngIf="layout > LAYOUT.XXS">
                <ng-template [ngIf]="!row.guestData?.company">
                  <b>
                    {{ row.guestData?.name ? row.guestData?.name : 'Kein Name' }}
                    {{ row.guestData?.firstName ? row.guestData?.firstName : '' }}</b
                  >
                </ng-template>
                <ng-template [ngIf]="row.guestData?.company">
                  <b>{{ row.guestData?.company }} ({{ row.guestData?.name ? row.guestData?.name : 'Kein Name' }})</b>
                </ng-template>
              </span>
              <b [ngClass]="{ 'line-strike': row?.status === 'canceled' }" *ngIf="layout < LAYOUT.XS">
                <ng-template [ngIf]="!row.guestData?.company">
                  <b
                    >{{ row.guestData?.name ? row.guestData?.name : 'Kein Name' }}
                    {{ row.guestData?.firstName ? row.guestData?.firstName : '' }}</b
                  >
                </ng-template>
                <ng-template [ngIf]="row.guestData?.company">
                  <b>{{ row.guestData?.company }} ({{ row.guestData?.name ? row.guestData?.name : 'Kein Name' }})</b>
                </ng-template>
              </b>
              <span *ngIf="row.guestData && row.guestData?.notes" matTooltip="{{ row.guestData?.notes }}">
                <i class="fas fa-exclamation-triangle details-icon"></i
              ></span>
              <span
                *ngIf="row.guestData && row.guestData.visits > 0"
                class="badge badge-default"
                style="margin-left: 10px;"
                matTooltip="Anzahl Besuche"
                (click)="getGuestReservationHistory(row.guestData)"
                >{{ row?.guestData?.visits }}</span
              >
              <span *ngIf="row.orderId" class="badge badge-default" matTooltip="Pre-order">
                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
              </span>
              <span
                *ngIf="row.guestData && row?.guestData?.noShow && row?.guestData?.noShow > 0"
                class="badge"
                style="background:red; color:white; text-align:center"
                matTooltip='Anzahl "no Shows"'
              >
                {{ row.guestData.noShow }}
              </span>
              &nbsp;
              <span *ngFor="let intolerance of row.guestData?.intoleranceValue">
                <span class="badge" style="background: pink;"> {{ intolerance }} </span>
              </span>
              <span *ngIf="row.files.length > 0" class="badge badge-default" style="margin-left: 10px;"
                ><i class="fas fa-file-alt"></i
              ></span>
              <div
                *ngIf="row.paymentTemplate"
                class="badge"
                [class.badge-light]="!row.paymentId"
                [class.badge-success]="row.paymentId"
                [matTooltip]="
                  row.paymentId ? 'Reservierung wurde angezahlt' : 'Reservierung wurde noch nicht angezahlt'
                "
              >
                <i class="fas fa-money-bill-wave"></i>
                <span *ngIf="row.paymentTransaction"> {{ row.paymentTransaction.value | number: '1.2' }} € </span>
              </div>
            </div>
          </ng-template>
          <ng-template [ngIf]="!row.guestData">
            <div>
              <span *ngIf="layout > LAYOUT.XXS">Walk-In</span>
              <b *ngIf="layout < LAYOUT.XS">Walk-In</b>
            </div>
          </ng-template>
          <!-- Larger display  -->
          <span class="date-label" *ngIf="layout > LAYOUT.XXS">
            {{ row.createdAt | amTimeAgo }}
            <mat-chip-list *ngIf="row.msg || row.notes">
              <mat-chip class="guest-note" *ngIf="row?.msg" [matTooltip]="row?.msg">Hinweis</mat-chip>
              <mat-chip class="admin-note" *ngIf="row?.notes" [matTooltip]="row?.notes">Notiz</mat-chip>
            </mat-chip-list>
          </span>
          <!-- /Larger display  -->

          <!-- Mobile responsive  -->
          <div class="status-label" *ngIf="layout < LAYOUT.XS">
            <ng-container *ngTemplateOutlet="showStatusTemplate; context: { row: row }"> </ng-container>
          </div>
          <!-- /Mobile responsive  -->
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [sortable]="true" [resizeable]="false" prop="peopleCount" name="Gäste" [flexGrow]="1">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div [class.text-right]="layout < LAYOUT.XS">
            <small> {{ row.peopleCount }} <span class="d-none d-xl-inline">Personen</span></small>
            <div
              [class.text-success]="row.checkins && row.checkins.length === row.peopleCount"
              [class.text-warning]="row.checkins && row.checkins.length < row.peopleCount"
              *ngIf="settings && settings.requestCheckin"
            >
              <small [matTooltip]="row.checkins ? (row.checkins | checkinsLabel) : ''"
                >{{ row.checkins?.length }} <span class="d-none d-xl-inline">eingecheckt</span></small
              >
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        [sortable]="true"
        [resizeable]="false"
        prop="tables"
        name="Tisch"
        [flexGrow]="1"
        [comparator]="sortTablesComparator"
        *ngIf="layout > LAYOUT.XXS"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="tables">
            <span *ngIf="!row?.tables?.length && row?.room" class="badge badge-default">
              {{ row.room.name }}
            </span>
            <ng-template ngFor let-table [ngForOf]="row?.tables">
              <span *ngIf="table" class="badge badge-default" placement="bottom" [ngbTooltip]="table?.room?.name">
                {{ table.name }}
              </span>
            </ng-template>
          </div>
          <div class="children-highchair" *ngIf="row.highChairs || row.children">
            <span class="badge badge-warning" placement="bottom" [ngbTooltip]="'Anzahl Kinder'" *ngIf="row.children">
              {{ row.children }} <i class="icon-child"></i>
            </span>
            <span class="badge badge-info" placement="bottom" [ngbTooltip]="'Anzahl Hochstühle'" *ngIf="row.highChairs">
              {{ row.highChairs }} <i class="icon-highchair"></i>
            </span>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        [sortable]="false"
        [resizeable]="false"
        prop="tags"
        name="Tags"
        [flexGrow]="1"
        *ngIf="layout > LAYOUT.XXS && !isColumnHidden('tags')"
      >
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          <div class="tags">
            <ng-template ngFor let-tag [ngForOf]="row.tags">
              <span class="badge" [style.background-color]="tag.color">
                {{ tag.label }}
              </span>
            </ng-template>
            <span *ngFor="let addOn of row.addOns">
              <span class="badge" style="background: lightskyblue;"> {{ addOn }} </span>&nbsp;
            </span>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        [sortable]="true"
        [resizeable]="false"
        prop="status"
        name="Status"
        [flexGrow]="1"
        cellClass="text-right"
        headerClass="text-right"
        *ngIf="layout > LAYOUT.XXS && bookSettings?.showStatus"
      >
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          <span
            *ngIf="row?.id && showReservationId"
            style="position: absolute;right: 8px;top: 6px;font-size: 12px !important;"
          >
            #{{ row.id }}&nbsp; &nbsp;
          </span>
          <div
            (click)="
              $event.preventDefault();
              row.status === 'finished' || row.status === 'noShow' ? return : (editing[rowIndex + '-status'] = true)
            "
            class="status-label"
            *ngIf="!editing[rowIndex + '-status']"
          >
            <ng-container *ngTemplateOutlet="showStatusTemplate; context: { row: row }"> </ng-container>
          </div>
          <ng-container *ngTemplateOutlet="statusChangeTemplate; context: { rowIndex: rowIndex, row: row }">
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        [sortable]="false"
        [cellClass]="'cell-align-right'"
        [resizeable]="false"
        [canAutoResize]="false"
        *ngIf="false"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <button class="btn btn-light" (click)="editReservation(row)">
            <i class="fas fa-edit"></i>
          </button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

    <div class="grid">
      <div style="padding: 2px;" *ngIf="totalReservations == 0">
        <p style="text-align: center;">{{ emptyMessage }}</p>
      </div>
      <div
        style="    margin-top: 5px;
      margin-bottom: 10px;"
        *ngIf="totalReservations > 0"
      >
        <mat-slide-toggle [(ngModel)]="isChecked" (change)="onValChange($event)">{{
          'ReservationList.ShowDetails' | translate
        }}</mat-slide-toggle>
      </div>
      <div class="table-footer" *ngIf="totalReservations > 0">
        <p style="font-size: 22px;">
          Insgesamt
          <strong>{{ totalReservations }} Reservierung<span *ngIf="totalReservations > 1">en</span></strong>
          mit
          <strong>{{ totalGuests === 1 ? 'einem Gast' : totalGuests + ' Gästen' }}</strong>
        </p>
      </div>

      <div *ngFor="let row of reservations; let i = index">
        <div class="row" (click)="editReservation(row)" *ngIf="isChecked">
          <div class="col-2"></div>
          <div class="col-6"></div>
          <div class="col-4 people-count-container">
            <span *ngIf="row?.id" style="font-size: 12px !important;">#{{ row.id }}</span>
          </div>
        </div>
        <div class="row" (click)="editReservation(row)">
          <div class="col-2">
            <span class="dates" style="font-weight: 600;">{{ row.startDate | dfnsFormat: 'HH:mm' }}</span>
            <hr class="line-btw-sdate-edate" />
          </div>
          <div class="col-8" style="font-weight: 600;">
            <ng-template style="word-break: break-word;" [ngIf]="!row.guestData?.company && row.source !== 'walkin'">
              {{ row.guestData?.name ? row.guestData?.name : 'Kein Name' }}
              <span style="font-size: 10px !important">{{
                row.guestData?.firstName ? row.guestData?.firstName : 'Kein Name'
              }}</span>
            </ng-template>
            <ng-template [ngIf]="row.guestData?.company">
              {{ row.guestData?.company }}
            </ng-template>
            <ng-template [ngIf]="!row.guestData && row.source === 'walkin'">
              <div>
                <!-- <span *ngIf="layout > LAYOUT.XS">Walk-In</span> -->
                <b>Walk-In</b>
              </div>
            </ng-template>
            <span
              *ngIf="row.guestData && row.guestData.visits > 0"
              class="badge badge-default counter-number"
              matTooltip="Anzahl Besuche"
              (click)="getGuestReservationHistory(row.guestData)"
              >{{ row.guestData.visits }}</span
            >
            <span *ngIf="row.orderId" class="badge badge-default" matTooltip="Pre-order">
              <i class="fa fa-shopping-cart" aria-hidden="true"></i>
            </span>
            <span
              *ngIf="row.guestData && row.guestData.noShow && row.guestData.noShow > 0"
              class="badge counter-number"
              style="background:red; color:white; text-align:center;"
              matTooltip='Anzahl "no Shows"'
            >
              {{ row.guestData.noShow }}
            </span>
          </div>
          <div class="col-2 people-count-container">
            <span class="people-count-text">
              {{ row.peopleCount }}
              <i class="fas fa-male" fa-fw></i>
            </span>
          </div>
        </div>

        <div class="row justify-content-end">
          <div class="col-2" *ngIf="!isChecked">
            <span class="end-date-hours">{{ row.endDate | dfnsFormat: 'HH:mm' }}</span>
          </div>
          <div class="col-6">
            <div class="tables" (click)="editReservation(row)">
              <span *ngIf="!row?.tables?.length && row?.room" class="badge badge-default counter-number">
                {{ row.room.name }}
              </span>
              <ng-template ngFor let-table [ngForOf]="row.tables">
                <span
                  *ngIf="table"
                  class="badge badge-default counter-number"
                  placement="bottom"
                  [ngbTooltip]="table?.room?.name"
                >
                  {{ table.name }}
                </span>
              </ng-template>
            </div>
            <div class="children-highchair" *ngIf="row.highChairs || row.children" (click)="editReservation(row)">
              <span
                class="badge badge-warning counter-number"
                placement="bottom"
                [ngbTooltip]="'Anzahl Kinder'"
                *ngIf="row.children"
              >
                {{ row.children }} <i class="icon-child"></i>
              </span>
              <span
                class="badge badge-info counter-number"
                placement="bottom"
                [ngbTooltip]="'Anzahl Hochstühle'"
                *ngIf="row.highChairs"
              >
                {{ row.highChairs }} <i class="icon-highchair"></i>
              </span>
            </div>
          </div>
          <div class="col-4 people-count-container">
            <ng-template *ngIf="row?.tags && !isChecked" ngFor let-tag [ngForOf]="row.tags">
              <i class="fa fa-tags" [style.color]="tag.color" [matTooltip]="tag.label"></i>
            </ng-template>
          </div>
        </div>

        <div class="row">
          <div class="col-2"></div>
          <div class="col-7">
            <div
              class="status-label"
              (click)="
                $event.preventDefault();
                row.status === 'finished' || row.status === 'noShow' ? return : (editing[i + '-status'] = true)
              "
              *ngIf="!editing[i + '-status']"
            >
              <ng-container *ngTemplateOutlet="showStatusTemplate; context: { row: row }"> </ng-container>
            </div>
            <ng-container *ngTemplateOutlet="statusChangeTemplate; context: { rowIndex: i, row: row }"> </ng-container>
          </div>
          <div class="col-3 people-count-container" *ngIf="!isChecked">
            <i class="fa fa-info-circle guest-note-table-plan" *ngIf="row.msg" [matTooltip]="row?.msg"></i>
            <i class="fa fa-info-circle admin-note-table-plan " *ngIf="row.notes" [matTooltip]="row?.notes"></i>
          </div>
        </div>
        <div class="row" *ngIf="isChecked && row?.tags">
          <div class="col-2"></div>
          <div class="col-10">
            <!-- <div *ngIf="row?.tags"> -->
            <ng-template *ngIf="row?.tags" ngFor let-tag [ngForOf]="row.tags">
              <span class="badge-tags" [style.background-color]="tag.color">
                {{ tag.label }}
              </span>
            </ng-template>
            <hr *ngIf="row?.tags.length > 0" class="divide-line-tags" />
            <!-- </div> -->
          </div>
        </div>

        <div class="row" *ngIf="(isChecked && row.msg) || row.notes">
          <div class="col-2"></div>
          <div class="col-10">
            <div *ngIf="isChecked">
              <mat-chip-list>
                <mat-chip class="guest-note" *ngIf="row.msg">{{ row.msg }}</mat-chip>
                <mat-chip class="admin-note" *ngIf="row.notes">{{ row.notes }}</mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-2"></div>
          <div
            *ngIf="row.paymentTemplate"
            class="badge"
            [class.badge-light]="!row.paymentId"
            [class.badge-success]="row.paymentId"
            [matTooltip]="row.paymentId ? 'Reservierung wurde angezahlt' : 'Reservierung wurde noch nicht angezahlt'"
          >
            <i class="fas fa-money-bill-wave"></i>
            <span *ngIf="row.paymentTransaction"> {{ row.paymentTransaction.value | number: '1.2' }} € </span>
          </div>
        </div>

        <hr />
      </div>
    </div>
  </div>

  <div class="datatable-body-cell-label" *ngIf="isArchiveList">
    <div class="table-footer" *ngIf="totalReservations > 0">
      <p style="font-size: 22px;">
        Insgesamt
        <strong>{{ totalReservations }} Reservierung<span *ngIf="totalReservations > 1">en</span></strong>
        mit
        <strong>{{ totalGuests === 1 ? 'einem Gast' : totalGuests + ' Gästen' }}</strong>
      </p>
    </div>
    <div class="datatable-res-list">
      <ngx-datatable
        #dataTable
        class="material"
        [columnMode]="'force'"
        headerHeight="25"
        footerHeight="50"
        rowHeight="auto"
        [rows]="reservations"
        [limit]="limit"
        [cssClasses]="{
          pagerLeftArrow: 'fas fa-backward',
          pagerRightArrow: 'fas fa-forward',
          pagerPrevious: 'fas fa-step-backward',
          pagerNext: 'fas fa-step-forward'
        }"
        [messages]="{ emptyMessage: emptyMessage }"
      >
        <ngx-datatable-column
          [sortable]="true"
          [canAutoResize]="false"
          [resizeable]="false"
          prop="reservedFor"
          name="Zeit"
          [width]="80"
          [summaryTemplate]="nameSummaryCell"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div style="display: flex; align-items: center;">
              <div>
                <b>
                  {{ row.reservedFor | dfnsFormat: 'HH:mm' }}
                </b>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [sortable]="true" name="Name" prop="guestData.name">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <b
              ><span
                >{{ row.guestData?.name ? row.guestData?.name : '' }}
                {{ row.guestData?.firstName ? row.guestData?.firstName : '' }}</span
              ></b
            >
            <span class="date-label" *ngIf="layout > LAYOUT.XXS">
              <mat-chip-list *ngIf="row.msg || row.reservationNotes">
                <mat-chip class="guest-note" *ngIf="row.msg" [matTooltip]="row.msg">Hinweis</mat-chip>
                <mat-chip class="admin-note" *ngIf="row.reservationNotes" [matTooltip]="row.reservationNotes"
                  >Notiz</mat-chip
                >
              </mat-chip-list> </span
            ><br />
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [sortable]="true" [resizeable]="false" prop="peopleCount" name="Gäste" [flexGrow]="0.5">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div [class.text-right]="layout < LAYOUT.XS">
              {{ row.peopleCount }} <span class="d-none d-xl-inline">Personen</span>
              <div
                [class.text-success]="row.checkins && row.checkins.length === row.peopleCount"
                [class.text-warning]="row.checkins && row.checkins.length < row.peopleCount"
                *ngIf="settings && settings.requestCheckin"
              >
                <small [matTooltip]="row.checkins ? (row.checkins | checkinsLabel) : ''"
                  >{{ row.checkins?.length }} <span class="d-none d-xl-inline">eingecheckt</span></small
                >
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [sortable]="true"
          [resizeable]="false"
          prop="tables"
          name="Tisch"
          [flexGrow]="1"
          [comparator]="sortTablesComparator"
          *ngIf="layout > LAYOUT.XXS"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="tables">
              <span *ngIf="!row?.tables?.length && row?.room" class="badge badge-default">
                {{ row.room.name }}
              </span>
              <ng-template ngFor let-table [ngForOf]="row?.tables">
                <span *ngIf="table" class="badge badge-default" placement="bottom" [ngbTooltip]="table?.room?.name">
                  {{ table.name }}
                </span>
              </ng-template>
            </div>
            <div class="children-highchair" *ngIf="row.highChairs || row.children">
              <span class="badge badge-warning" placement="bottom" [ngbTooltip]="'Anzahl Kinder'" *ngIf="row.children">
                {{ row.children }} <i class="icon-child"></i>
              </span>
              <span
                class="badge badge-info"
                placement="bottom"
                [ngbTooltip]="'Anzahl Hochstühle'"
                *ngIf="row.highChairs"
              >
                {{ row.highChairs }} <i class="icon-highchair"></i>
              </span>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [sortable]="false"
          [resizeable]="false"
          prop="tags"
          name="Tags"
          [flexGrow]="1"
          *ngIf="layout > LAYOUT.XXS && !isColumnHidden('tags')"
        >
          <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
            <div class="tags">
              <ng-template ngFor let-tag [ngForOf]="row.tags">
                <span class="badge" [style.background-color]="tag.color">
                  {{ tag.label }}
                </span>
              </ng-template>
              <span *ngFor="let addOn of row.addOns">
                <span class="badge" style="background: lightskyblue;"> {{ addOn }} </span>&nbsp;
              </span>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [sortable]="true"
          [resizeable]="false"
          prop="status"
          name="Status"
          [flexGrow]="1"
          cellClass="text-right"
          headerClass="text-right"
          *ngIf="layout > LAYOUT.XXS && bookSettings?.showStatus"
        >
          <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
            <span *ngIf="row?.id" style="position: absolute;right: 8px;top: 0px;font-size: 12px !important;">
              #{{ row.id }}&nbsp; &nbsp;
            </span>
            <div class="status-label" *ngIf="!editing[rowIndex + '-status']" style="margin-top: 10px;">
              <ng-container *ngTemplateOutlet="showStatusTemplate; context: { row: row }"> </ng-container>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
      <div class="grid">
        <div style="padding: 2px;" *ngIf="totalReservations == 0">
          <p style="text-align: center;">{{ emptyMessage }}</p>
        </div>
        <div style="margin-top: 5px; margin-bottom: 10px;" *ngIf="totalReservations > 0">
          <mat-slide-toggle [(ngModel)]="isChecked" (change)="onValChange($event)">{{
            'ReservationList.ShowDetails' | translate
          }}</mat-slide-toggle>
        </div>
        <div *ngFor="let row of reservations; let i = index">
          <div class="row" (click)="editReservation(row)" *ngIf="isChecked">
            <div class="col-2"></div>
            <div class="col-6"></div>
            <div class="col-4 people-count-container">
              <span *ngIf="row?.id" style="font-size: 12px !important;">#{{ row.id }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <span class="dates" style="font-weight: 600;">{{ row.startDate | dfnsFormat: 'HH:mm' }}</span>
            </div>
            <div class="col-8" style="font-weight: 600;">
              <ng-template style="word-break: break-word;" [ngIf]="!row.guestData?.company && row.source !== 'walkin'">
                {{ row.guestData?.name ? row.guestData?.name : 'Kein Name' }}
                <span style="font-size: 10px !important">{{
                  row.guestData?.firstName ? row.guestData?.firstName : 'Kein Name'
                }}</span>
              </ng-template>
              <ng-template [ngIf]="row.guestData?.company">
                {{ row.guestData?.company }}
              </ng-template>
              <ng-template [ngIf]="!row.guestData && row.source === 'walkin'">
                <div>
                  <!-- <span *ngIf="layout > LAYOUT.XS">Walk-In</span> -->
                  <b>Walk-In</b>
                </div>
              </ng-template>
              <span
                *ngIf="row.guestData && row.guestData.visits > 0"
                class="badge badge-default"
                style="margin-left: 2px;"
                matTooltip="Anzahl Besuche"
                (click)="getGuestReservationHistory(row.guestData)"
                >{{ row.guestData.visits }}</span
              >
              <span *ngIf="row.orderId" class="badge badge-default" matTooltip="Pre-order">
                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
              </span>
              <span
                *ngIf="row.guestData && row.guestData.noShow && row.guestData.noShow > 0"
                class="badge"
                style="background:red; color:white; text-align:center;"
                matTooltip='Anzahl "no Shows"'
              >
                {{ row.guestData.noShow }}
              </span>
            </div>
            <div class="col-2 people-count-container">
              <span class="people-count-text">
                {{ row.peopleCount }}
                <i class="fas fa-male" fa-fw></i>
              </span>
            </div>
          </div>

          <div class="row justify-content-end">
            <div class="col-2" *ngIf="!isChecked">
              <span>{{ row.endDate | dfnsFormat: 'HH:mm' }}</span>
            </div>
            <div class="col-6">
              <div class="status-label" *ngIf="!editing[i + '-status']">
                <ng-container *ngTemplateOutlet="showStatusTemplate; context: { row: row }"> </ng-container>
              </div>
            </div>
            <div class="col-4 people-count-container">
              <ng-template *ngIf="row?.tags.lenght > 0 && !isChecked" ngFor let-tag [ngForOf]="row?.tags">
                <i class="fa fa-tags" [style.color]="tag?.color" [matTooltip]="tag?.label"></i>
              </ng-template>
              <ng-template *ngIf="row?.tags && isChecked" ngFor let-tag [ngForOf]="row?.tags">
                <span class="badge-tags" [style.background-color]="tag?.color">
                  {{ tag.label }}
                </span>
              </ng-template>
            </div>
          </div>

          <div class="row">
            <div class="col-2"></div>
            <div class="col-6">
              <div class="tables">
                <span *ngIf="!row?.tables?.length && row?.room" class="badge badge-default">
                  {{ row.room.name }}
                </span>
                <ng-template ngFor let-table [ngForOf]="row.tables">
                  <span *ngIf="table" class="badge badge-default" placement="bottom" [ngbTooltip]="table?.room?.name">
                    {{ table.name }}
                  </span>
                </ng-template>
              </div>
              <div class="children-highchair" *ngIf="row.highChairs || row.children">
                <span
                  class="badge badge-warning"
                  placement="bottom"
                  [ngbTooltip]="'Anzahl Kinder'"
                  *ngIf="row?.children"
                >
                  {{ row.children }} <i class="icon-child"></i>
                </span>
                <span
                  class="badge badge-info"
                  placement="bottom"
                  [ngbTooltip]="'Anzahl Hochstühle'"
                  *ngIf="row?.highChairs"
                >
                  {{ row.highChairs }} <i class="icon-highchair"></i>
                </span>
              </div>
            </div>
            <div class="col-4 people-count-container">
              <div *ngIf="!isChecked">
                <i class="fa fa-info-circle guest-note-table-plan" *ngIf="row.msg" [matTooltip]="row.msg"></i>
                <i class="fa fa-info-circle admin-note-table-plan " *ngIf="row.notes" [matTooltip]="row.notes"></i>
              </div>

              <div *ngIf="isChecked">
                <mat-chip-list>
                  <mat-chip class="guest-note" *ngIf="row.msg">{{ row.msg }}</mat-chip>
                  <mat-chip class="admin-note" *ngIf="row.notes">{{ row.notes }}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Data Table to show value if select logsFile reservation-list -->
<div *ngIf="!loadingTable && reservationListActive === true" [ngClass]="fsValue" class="ngx-datatable-template">
  <div class="datatable-res-list" *ngIf="!isArchiveList">
    <ngx-datatable
      #dataTable
      class="bootstrap reservations-table table-to-show-list expandable "
      [columnMode]="'flex'"
      [headerHeight]="35"
      [footerHeight]="50"
      [summaryRow]="showSummary"
      [loadingIndicator]="loading"
      [summaryHeight]="'auto'"
      [rowHeight]="'auto'"
      [virtualization]="false"
      [scrollbarV]="false"
      [scrollbarH]="false"
      [rows]="sortReservationList"
      [groupRowsBy]="'date'"
      [groupExpansionDefault]="true"
      [limit]="limit"
      [cssClasses]="{
        pagerLeftArrow: 'fas fa-backward',
        pagerRightArrow: 'fas fa-forward',
        pagerPrevious: 'fas fa-step-backward',
        pagerNext: 'fas fa-step-forward'
      }"
      (activate)="onRowActivate($event)"
      [sorts]="[{ prop: 'date', dir: 'asc' }]"
      [messages]="{ emptyMessage: emptyMessage, total: ' insgesamt' }"
      (sort)="onSort($event)"
      [rowClass]="getRowClass"
    >
      <ngx-datatable-group-header [rowHeight]="50">
        <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
          <div style="padding: 10px;">
            <b>{{ group.value[0].date | date: 'dd.MM.yyyy' }}</b>
          </div>
        </ng-template>
      </ngx-datatable-group-header>

      <ngx-datatable-column
        [width]="50"
        [resizeable]="false"
        [sortable]="false"
        [draggable]="false"
        [canAutoResize]="false"
        [summaryTemplate]="nameSummaryCell"
      >
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template></ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [sortable]="true" [resizeable]="false" prop="guestData.name" name="Name" [flexGrow]="2">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <ng-template [ngIf]="row.guestData">
            <div style="display: flex; align-items: center;">
              <span
                *ngIf="row && row.shift"
                class="status-pill smaller"
                [matTooltip]="row.shift.name"
                [ngStyle]="{ 'background-color': row.shift ? row.shift.color : '#fff' }"
              ></span>

              <span [ngClass]="{ 'line-strike': row?.status === 'canceled' }" *ngIf="layout > LAYOUT.XXS">
                <ng-template [ngIf]="!row.guestData?.company">
                  <b>
                    {{ row.guestData?.name ? row.guestData?.name : 'Kein Name' }}
                    {{ row.guestData?.firstName ? row.guestData?.firstName : '' }}
                  </b>
                </ng-template>
                <ng-template [ngIf]="row.guestData?.company">
                  <b>{{ row.guestData?.company }} ({{ row.guestData?.name ? row.guestData?.name : 'Kein Name' }})</b>
                </ng-template>
              </span>
              <b [ngClass]="{ 'line-strike': row?.status === 'canceled' }" *ngIf="layout < LAYOUT.XS">
                <ng-template [ngIf]="!row.guestData?.company">
                  <b
                    >{{ row.guestData?.name ? row.guestData?.name : 'Kein Name' }}
                    {{ row.guestData?.firstName ? row.guestData?.firstName : '' }}</b
                  >
                </ng-template>
                <ng-template [ngIf]="row.guestData?.company">
                  <b>{{ row.guestData?.company }} ({{ row.guestData?.name ? row.guestData?.name : 'Kein Name' }})</b>
                </ng-template>
              </b>
              <span *ngIf="row.guestData && row.guestData?.notes" matTooltip="{{ row.guestData?.notes }}">
                <i class="fas fa-exclamation-triangle details-icon"></i
              ></span>
              <span
                *ngIf="row.guestData && row.guestData.visits > 0"
                class="badge badge-default"
                style="margin-left: 10px;"
                matTooltip="Anzahl Besuche"
                (click)="getGuestReservationHistory(row.guestData)"
                >{{ row?.guestData?.visits }}</span
              >
              <span *ngIf="row.orderId" class="badge badge-default" matTooltip="Pre-order">
                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
              </span>
              <span
                *ngIf="row.guestData && row?.guestData?.noShow && row?.guestData?.noShow > 0"
                class="badge"
                style="background:red; color:white; text-align:center"
                matTooltip='Anzahl "no Shows"'
              >
                {{ row.guestData.noShow }}
              </span>
              &nbsp;
              <span *ngFor="let intolerance of row.guestData?.intoleranceValue">
                <span class="badge" style="background: pink;"> {{ intolerance }} </span>
              </span>
              <span *ngIf="row.files.length > 0" class="badge badge-default" style="margin-left: 10px;"
                ><i class="fas fa-file-alt"></i
              ></span>
              <div
                *ngIf="row.paymentTemplate"
                class="badge"
                [class.badge-light]="!row.paymentId"
                [class.badge-success]="row.paymentId"
                [matTooltip]="
                  row.paymentId ? 'Reservierung wurde angezahlt' : 'Reservierung wurde noch nicht angezahlt'
                "
              >
                <i class="fas fa-money-bill-wave"></i>
                <span *ngIf="row.paymentTransaction"> {{ row.paymentTransaction.value | number: '1.2' }} € </span>
              </div>
            </div>
          </ng-template>
          <ng-template [ngIf]="!row.guestData">
            <div>
              <span *ngIf="layout > LAYOUT.XXS">Walk-In</span>
              <b *ngIf="layout < LAYOUT.XS">Walk-In</b>
            </div>
          </ng-template>
          <!-- Larger display  -->
          <span class="date-label" *ngIf="layout > LAYOUT.XXS">
            {{ row.createdAt | amTimeAgo }}
            <mat-chip-list *ngIf="row.msg || row.notes">
              <mat-chip class="guest-note" *ngIf="row?.msg" [matTooltip]="row?.msg">Hinweis</mat-chip>
              <mat-chip class="admin-note" *ngIf="row?.notes" [matTooltip]="row?.notes">Notiz</mat-chip>
            </mat-chip-list>
          </span>
          <!-- /Larger display  -->

          <!-- Mobile responsive  -->
          <div class="status-label" *ngIf="layout < LAYOUT.XS">
            <ng-container *ngTemplateOutlet="showStatusTemplate; context: { row: row }"> </ng-container>
          </div>
          <!-- /Mobile responsive  -->
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [sortable]="true" [resizeable]="false" prop="peopleCount" name="Gäste" [flexGrow]="0.5">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div [class.text-right]="layout < LAYOUT.XS">
            <small> {{ row.peopleCount }} <span class="d-none d-xl-inline">Personen</span></small>
            <div
              [class.text-success]="row.checkins && row.checkins.length === row.peopleCount"
              [class.text-warning]="row.checkins && row.checkins.length < row.peopleCount"
              *ngIf="settings && settings.requestCheckin"
            >
              <small [matTooltip]="row.checkins ? (row.checkins | checkinsLabel) : ''"
                >{{ row.checkins?.length }} <span class="d-none d-xl-inline">eingecheckt</span></small
              >
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        [sortable]="true"
        [resizeable]="false"
        prop="tables"
        name="Tisch"
        [flexGrow]="1"
        [comparator]="sortTablesComparator"
        *ngIf="layout > LAYOUT.XXS"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="tables">
            <span *ngIf="!row?.tables?.length && row?.room" class="badge badge-default">
              {{ row.room.name }}
            </span>
            <ng-template ngFor let-table [ngForOf]="row?.tables">
              <span *ngIf="table" class="badge badge-default" placement="bottom" [ngbTooltip]="table?.room?.name">
                {{ table.name }}
              </span>
            </ng-template>
          </div>
          <div class="children-highchair" *ngIf="row.highChairs || row.children">
            <span class="badge badge-warning" placement="bottom" [ngbTooltip]="'Anzahl Kinder'" *ngIf="row.children">
              {{ row.children }} <i class="icon-child"></i>
            </span>
            <span class="badge badge-info" placement="bottom" [ngbTooltip]="'Anzahl Hochstühle'" *ngIf="row.highChairs">
              {{ row.highChairs }} <i class="icon-highchair"></i>
            </span>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        [sortable]="false"
        [resizeable]="false"
        prop="tags"
        name="Tags"
        [flexGrow]="1"
        *ngIf="layout > LAYOUT.XXS && !isColumnHidden('tags')"
      >
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          <div class="tags">
            <ng-template ngFor let-tag [ngForOf]="row.tags">
              <span class="badge" [style.background-color]="tag.color">
                {{ tag.label }}
              </span>
            </ng-template>
            <span *ngFor="let addOn of row.addOns">
              <span class="badge" style="background: lightskyblue;"> {{ addOn }} </span>&nbsp;
            </span>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        [sortable]="true"
        [resizeable]="false"
        prop="status"
        name="Status"
        [flexGrow]="1"
        cellClass="text-right"
        headerClass="text-right"
        *ngIf="layout > LAYOUT.XXS && bookSettings?.showStatus"
      >
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          <span
            *ngIf="row?.id && showReservationId"
            style="position: absolute;right: 8px;top: 6px;font-size: 12px !important;"
          >
            #{{ row.id }}&nbsp; &nbsp;
          </span>
          <div
            (click)="
              $event.preventDefault();
              row.status === 'finished' || row.status === 'noShow' ? return : (editing[rowIndex + '-status'] = true)
            "
            class="status-label"
            *ngIf="!editing[rowIndex + '-status']"
          >
            <ng-container *ngTemplateOutlet="showStatusTemplate; context: { row: row }"> </ng-container>
          </div>
          <ng-container *ngTemplateOutlet="statusChangeTemplate; context: { rowIndex: rowIndex, row: row }">
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        [sortable]="false"
        [cellClass]="'cell-align-right'"
        [resizeable]="false"
        [canAutoResize]="false"
        *ngIf="false"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <button class="btn btn-light" (click)="editReservation(row)">
            <i class="fas fa-edit"></i>
          </button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

    <div class="grid">
      <div style="padding: 2px;" *ngIf="totalReservations == 0">
        <p style="text-align: center;">{{ emptyMessage }}</p>
      </div>
      <div
        style="    margin-top: 5px;
      margin-bottom: 10px;"
        *ngIf="totalReservations > 0"
      >
        <mat-slide-toggle [(ngModel)]="isChecked" (change)="onValChange($event)">{{
          'ReservationList.ShowDetails' | translate
        }}</mat-slide-toggle>
      </div>
      <div class="table-footer" *ngIf="totalReservations > 0">
        <p style="font-size: 22px;">
          Insgesamt
          <strong>{{ totalReservations }} Reservierung<span *ngIf="totalReservations > 1">en</span></strong>
          mit
          <strong>{{ totalGuests === 1 ? 'einem Gast' : totalGuests + ' Gästen' }}</strong>
        </p>
      </div>

      <div *ngFor="let row of sortReservationList; let i = index">
        <div class="row" (click)="editReservation(row)" *ngIf="isChecked">
          <div class="col-2"></div>
          <div class="col-6"></div>
          <div class="col-4 people-count-container">
            <span *ngIf="row?.id" style="font-size: 12px !important;">#{{ row.id }}</span>
          </div>
        </div>
        <div class="row" (click)="editReservation(row)">
          <!-- style="border-bottom: 1px solid #cecece; -->
          <div class="col-2">
            <span class="dates" style="font-weight: 600;">{{ row.startDate | dfnsFormat: 'HH:mm' }}</span>
            <hr class="line-btw-sdate-edate" />
          </div>
          <div class="col-8" style="font-weight: 600;">
            <ng-template style="word-break: break-word;" [ngIf]="!row.guestData?.company && row.source !== 'walkin'">
              {{ row.guestData?.name ? row.guestData?.name : 'Kein Name' }}
              <span style="font-size: 10px !important">{{
                row.guestData?.firstName ? row.guestData?.firstName : 'Kein Name'
              }}</span>
            </ng-template>
            <ng-template [ngIf]="row.guestData?.company">
              {{ row.guestData?.company }}
            </ng-template>
            <ng-template [ngIf]="!row.guestData && row.source === 'walkin'">
              <div>
                <!-- <span *ngIf="layout > LAYOUT.XS">Walk-In</span> -->
                <b>Walk-In</b>
              </div>
            </ng-template>
            <span
              *ngIf="row.guestData && row.guestData.visits > 0"
              class="badge badge-default counter-number"
              matTooltip="Anzahl Besuche"
              (click)="getGuestReservationHistory(row.guestData)"
              >{{ row.guestData.visits }}</span
            >
            <span *ngIf="row.orderId" class="badge badge-default" matTooltip="Pre-order">
              <i class="fa fa-shopping-cart" aria-hidden="true"></i>
            </span>
            <span
              *ngIf="row.guestData && row.guestData.noShow && row.guestData.noShow > 0"
              class="badge counter-number"
              style="background:red; color:white; text-align:center;"
              matTooltip='Anzahl "no Shows"'
            >
              {{ row.guestData.noShow }}
            </span>
          </div>
          <div class="col-2 people-count-container">
            <span class="people-count-text">
              {{ row.peopleCount }}
              <i class="fas fa-male" fa-fw></i>
            </span>
          </div>
        </div>

        <div class="row justify-content-end">
          <div class="col-2" *ngIf="!isChecked">
            <span class="end-date-hours">{{ row.endDate | dfnsFormat: 'HH:mm' }}</span>
          </div>
          <div class="col-6">
            <div class="tables" (click)="editReservation(row)">
              <span *ngIf="!row?.tables?.length && row?.room" class="badge badge-default counter-number">
                {{ row.room.name }}
              </span>
              <ng-template ngFor let-table [ngForOf]="row.tables">
                <span
                  *ngIf="table"
                  class="badge badge-default counter-number"
                  placement="bottom"
                  [ngbTooltip]="table?.room?.name"
                >
                  {{ table.name }}
                </span>
              </ng-template>
            </div>
            <div class="children-highchair" *ngIf="row.highChairs || row.children" (click)="editReservation(row)">
              <span
                class="badge badge-warning counter-number"
                placement="bottom"
                [ngbTooltip]="'Anzahl Kinder'"
                *ngIf="row.children"
              >
                {{ row.children }} <i class="icon-child"></i>
              </span>
              <span
                class="badge badge-info counter-number"
                placement="bottom"
                [ngbTooltip]="'Anzahl Hochstühle'"
                *ngIf="row.highChairs"
              >
                {{ row.highChairs }} <i class="icon-highchair"></i>
              </span>
            </div>
          </div>
          <div class="col-4 people-count-container">
            <ng-template *ngIf="row?.tags && !isChecked" ngFor let-tag [ngForOf]="row.tags">
              <i class="fa fa-tags" [style.color]="tag.color" [matTooltip]="tag.label"></i>
            </ng-template>
          </div>
        </div>

        <div class="row">
          <div class="col-2"></div>
          <div class="col-7">
            <div
              class="status-label"
              (click)="
                $event.preventDefault();
                row.status === 'finished' || row.status === 'noShow' ? return : (editing[i + '-status'] = true)
              "
              *ngIf="!editing[i + '-status']"
            >
              <ng-container *ngTemplateOutlet="showStatusTemplate; context: { row: row }"> </ng-container>
            </div>
            <ng-container *ngTemplateOutlet="statusChangeTemplate; context: { rowIndex: i, row: row }"> </ng-container>
          </div>
          <div class="col-3 people-count-container" *ngIf="!isChecked">
            <i class="fa fa-info-circle guest-note-table-plan" *ngIf="row.msg" [matTooltip]="row?.msg"></i>
            <i class="fa fa-info-circle admin-note-table-plan " *ngIf="row.notes" [matTooltip]="row?.notes"></i>
          </div>
        </div>
        <div class="row" *ngIf="isChecked && row?.tags">
          <div class="col-2"></div>
          <div class="col-10">
            <!-- <div *ngIf="row?.tags"> -->
            <ng-template *ngIf="row?.tags" ngFor let-tag [ngForOf]="row.tags">
              <span class="badge-tags" [style.background-color]="tag.color">
                {{ tag.label }}
              </span>
            </ng-template>
            <hr *ngIf="row?.tags.length > 0" class="divide-line-tags" />
            <!-- </div> -->
          </div>
        </div>

        <div class="row" *ngIf="(isChecked && row.msg) || row.notes">
          <div class="col-2"></div>
          <div class="col-10">
            <div *ngIf="isChecked">
              <mat-chip-list>
                <mat-chip class="guest-note" *ngIf="row.msg">{{ row.msg }}</mat-chip>
                <mat-chip class="admin-note" *ngIf="row.notes">{{ row.notes }}</mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-2"></div>
          <div
            *ngIf="row.paymentTemplate"
            class="badge"
            [class.badge-light]="!row.paymentId"
            [class.badge-success]="row.paymentId"
            [matTooltip]="row.paymentId ? 'Reservierung wurde angezahlt' : 'Reservierung wurde noch nicht angezahlt'"
          >
            <i class="fas fa-money-bill-wave"></i>
            <span *ngIf="row.paymentTransaction"> {{ row.paymentTransaction.value | number: '1.2' }} € </span>
          </div>
        </div>

        <hr />
      </div>
    </div>
  </div>

  <div class="datatable-body-cell-label" *ngIf="isArchiveList">
    <div class="table-footer" *ngIf="totalReservations > 0">
      <p style="font-size: 22px;">
        Insgesamt
        <strong>{{ totalReservations }} Reservierung<span *ngIf="totalReservations > 1">en</span></strong>
        mit
        <strong>{{ totalGuests === 1 ? 'einem Gast' : totalGuests + ' Gästen' }}</strong>
      </p>
    </div>
    <div class="datatable-res-list">
      <ngx-datatable
        #dataTable
        class="material"
        [columnMode]="'force'"
        headerHeight="25"
        footerHeight="50"
        rowHeight="auto"
        [rows]="reservations"
        [limit]="limit"
        [cssClasses]="{
          pagerLeftArrow: 'fas fa-backward',
          pagerRightArrow: 'fas fa-forward',
          pagerPrevious: 'fas fa-step-backward',
          pagerNext: 'fas fa-step-forward'
        }"
        [messages]="{ emptyMessage: emptyMessage }"
      >
        <ngx-datatable-column
          [sortable]="true"
          [canAutoResize]="false"
          [resizeable]="false"
          prop="reservedFor"
          name="Zeit"
          [width]="80"
          [summaryTemplate]="nameSummaryCell"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div style="display: flex; align-items: center;">
              <div>
                <b>
                  {{ row.reservedFor | dfnsFormat: 'HH:mm' }}
                </b>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [sortable]="true" name="Name" prop="guestData.name">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <b>
              <span
                >{{ row.guestData?.name ? row.guestData?.name : '' }}
                {{ row.guestData?.firstName ? row.guestData?.firstName : '' }}</span
              ></b
            >
            <span class="date-label" *ngIf="layout > LAYOUT.XXS">
              <mat-chip-list *ngIf="row.msg || row.reservationNotes">
                <mat-chip class="guest-note" *ngIf="row.msg" [matTooltip]="row.msg">Hinweis</mat-chip>
                <mat-chip class="admin-note" *ngIf="row.reservationNotes" [matTooltip]="row.reservationNotes"
                  >Notiz</mat-chip
                >
              </mat-chip-list> </span
            ><br />
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [sortable]="true" [resizeable]="false" prop="peopleCount" name="Gäste" [flexGrow]="0.5">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div [class.text-right]="layout < LAYOUT.XS">
              {{ row.peopleCount }} <span class="d-none d-xl-inline">Personen</span>
              <div
                [class.text-success]="row.checkins && row.checkins.length === row.peopleCount"
                [class.text-warning]="row.checkins && row.checkins.length < row.peopleCount"
                *ngIf="settings && settings.requestCheckin"
              >
                <small [matTooltip]="row.checkins ? (row.checkins | checkinsLabel) : ''"
                  >{{ row.checkins?.length }} <span class="d-none d-xl-inline">eingecheckt</span></small
                >
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [sortable]="true"
          [resizeable]="false"
          prop="status"
          name="Status"
          [flexGrow]="1"
          cellClass="text-right"
          headerClass="text-right"
          *ngIf="layout > LAYOUT.XXS && bookSettings?.showStatus"
        >
          <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
            <span *ngIf="row?.id" style="position: absolute;right: 8px;top: 0px;font-size: 12px !important;">
              #{{ row.id }}&nbsp; &nbsp;
            </span>
            <div class="status-label" *ngIf="!editing[rowIndex + '-status']" style="margin-top: 10px;">
              <ng-container *ngTemplateOutlet="showStatusTemplate; context: { row: row }"> </ng-container>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
      <div class="grid">
        <div style="padding: 2px;" *ngIf="totalReservations == 0">
          <p style="text-align: center;">{{ emptyMessage }}</p>
        </div>
        <div style="margin-top: 5px; margin-bottom: 10px;" *ngIf="totalReservations > 0">
          <mat-slide-toggle [(ngModel)]="isChecked" (change)="onValChange($event)">{{
            'ReservationList.ShowDetails' | translate
          }}</mat-slide-toggle>
        </div>
        <div *ngFor="let row of reservations; let i = index">
          <div class="row" (click)="editReservation(row)" *ngIf="isChecked">
            <div class="col-2"></div>
            <div class="col-6"></div>
            <div class="col-4 people-count-container">
              <span *ngIf="row?.id" style="font-size: 12px !important;">#{{ row.id }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <span class="dates" style="font-weight: 600;">{{ row.startDate | dfnsFormat: 'HH:mm' }}</span>
            </div>
            <div class="col-8" style="font-weight: 600;">
              <ng-template style="word-break: break-word;" [ngIf]="!row.guestData?.company && row.source !== 'walkin'">
                {{ row.guestData?.name ? row.guestData?.name : 'Kein Name' }}
                <span style="font-size: 10px !important">{{
                  row.guestData?.firstName ? row.guestData?.firstName : 'Kein Name'
                }}</span>
              </ng-template>
              <ng-template [ngIf]="row.guestData?.company">
                {{ row.guestData?.company }}
              </ng-template>
              <ng-template [ngIf]="!row.guestData && row.source === 'walkin'">
                <div>
                  <!-- <span *ngIf="layout > LAYOUT.XS">Walk-In</span> -->
                  <b>Walk-In</b>
                </div>
              </ng-template>
              <span
                *ngIf="row.guestData && row.guestData.visits > 0"
                class="badge badge-default"
                style="margin-left: 2px;"
                matTooltip="Anzahl Besuche"
                (click)="getGuestReservationHistory(row.guestData)"
                >{{ row.guestData.visits }}</span
              >
              <span *ngIf="row.orderId" class="badge badge-default" matTooltip="Pre-order">
                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
              </span>
              <span
                *ngIf="row.guestData && row.guestData.noShow && row.guestData.noShow > 0"
                class="badge"
                style="background:red; color:white; text-align:center;"
                matTooltip='Anzahl "no Shows"'
              >
                {{ row.guestData.noShow }}
              </span>
            </div>
            <div class="col-2 people-count-container">
              <span class="people-count-text">
                {{ row.peopleCount }}
                <i class="fas fa-male" fa-fw></i>
              </span>
            </div>
          </div>

          <div class="row justify-content-end">
            <div class="col-2" *ngIf="!isChecked">
              <span>{{ row.endDate | dfnsFormat: 'HH:mm' }}</span>
            </div>
            <div class="col-6">
              <div class="status-label" *ngIf="!editing[i + '-status']">
                <ng-container *ngTemplateOutlet="showStatusTemplate; context: { row: row }"> </ng-container>
              </div>
            </div>
            <div class="col-4 people-count-container">
              <ng-template *ngIf="row?.tags.lenght > 0 && !isChecked" ngFor let-tag [ngForOf]="row?.tags">
                <i class="fa fa-tags" [style.color]="tag?.color" [matTooltip]="tag?.label"></i>
              </ng-template>
              <ng-template *ngIf="row?.tags && isChecked" ngFor let-tag [ngForOf]="row?.tags">
                <span class="badge-tags" [style.background-color]="tag?.color">
                  {{ tag.label }}
                </span>
              </ng-template>
            </div>
          </div>

          <div class="row">
            <div class="col-2"></div>
            <div class="col-6">
              <div class="tables">
                <span *ngIf="!row?.tables?.length && row?.room" class="badge badge-default">
                  {{ row.room.name }}
                </span>
                <ng-template ngFor let-table [ngForOf]="row.tables">
                  <span *ngIf="table" class="badge badge-default" placement="bottom" [ngbTooltip]="table?.room?.name">
                    {{ table.name }}
                  </span>
                </ng-template>
              </div>
              <div class="children-highchair" *ngIf="row.highChairs || row.children">
                <span
                  class="badge badge-warning"
                  placement="bottom"
                  [ngbTooltip]="'Anzahl Kinder'"
                  *ngIf="row?.children"
                >
                  {{ row.children }} <i class="icon-child"></i>
                </span>
                <span
                  class="badge badge-info"
                  placement="bottom"
                  [ngbTooltip]="'Anzahl Hochstühle'"
                  *ngIf="row?.highChairs"
                >
                  {{ row.highChairs }} <i class="icon-highchair"></i>
                </span>
              </div>
            </div>
            <div class="col-4 people-count-container">
              <div *ngIf="!isChecked">
                <i class="fa fa-info-circle guest-note-table-plan" *ngIf="row.msg" [matTooltip]="row.msg"></i>
                <i class="fa fa-info-circle admin-note-table-plan " *ngIf="row.notes" [matTooltip]="row.notes"></i>
              </div>

              <div *ngIf="isChecked">
                <mat-chip-list>
                  <mat-chip class="guest-note" *ngIf="row.msg">{{ row.msg }}</mat-chip>
                  <mat-chip class="admin-note" *ngIf="row.notes">{{ row.notes }}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #showStatusTemplate let-row="row">
  <span [ngClass]="row?.statusInfo?.class" [style.color]="row?.statusInfo?.color">
    <i [class]="row?.statusInfo?.icon"></i>
    {{ row?.statusInfo?.label }}
  </span>
</ng-template>

<ng-template #statusChangeTemplate let-rowIndex="rowIndex" let-row="row">
  <div *ngIf="editing[rowIndex + '-status']" class="status-buttons">
    <!-- On pending and blocked status -->
    <ng-template [ngIf]="(row.status === 'pending' || row.status === 'blocked') && row.showValue === false">
      <button
        class="btn btn-success btn-sm"
        matTooltip="Bestätigt"
        (click)="updateStatus('confirmed', 'status', rowIndex, row)"
      >
        <i class="fas fa-check-circle fa-fw"></i>
      </button>
      <ng-template [ngIf]="!(row.paymentTemplate || row.ticketOrderId)" [ngIfElse]="withPermissions">
        <button
          class="btn btn-danger btn-sm"
          matTooltip="Storniert"
          (click)="updateStatus('canceled', 'status', rowIndex, row)"
        >
          <i class="fas fa-ban fa-fw"></i>
        </button>
      </ng-template>

      <ng-template #withPermissions>
        <button
          class="btn btn-danger btn-sm"
          matTooltip="Storniert"
          *ngxPermissionsOnly="['admin', 'manageReservationSettings']"
          (click)="updateStatus('canceled', 'status', rowIndex, row)"
        >
          <i class="fas fa-ban fa-fw"></i>
        </button>
      </ng-template>
    </ng-template>
    <!-- /On pending and blocked status -->

    <!-- On confirmed status -->
    <ng-template [ngIf]="row.status === 'confirmed' && row.showValue === false">
      <ng-template [ngIf]="(row.reservedFor | dfnsDifferenceInHours: todayDate) < 2 ? true : false">
        <button
          class="btn btn-secondary btn-sm btn-arrived"
          matTooltip="Angekommen"
          (click)="updateStatus('arrived', 'status', rowIndex, row)"
        >
          <i class="fas fa-store-alt fa-fw"></i>
        </button>
        <button
          class="btn btn-info btn-sm"
          matTooltip="Platziert"
          (click)="updateStatus('placed', 'status', rowIndex, row)"
        >
          <i class="fas fa-chair fa-fw"></i>
        </button>
        <button
          class="btn btn-success btn-sm btn-finished"
          matTooltip="Fertig"
          (click)="updateStatus('finished', 'status', rowIndex, row)"
        >
          <i class="fas fa-check-double fa-fw"></i>
        </button>
        <button
          class="btn btn-danger btn-sm btn-noshow"
          matTooltip="No-Show"
          (click)="updateStatus('noShow', 'status', rowIndex, row)"
        >
          <i class="fas fa-eye-slash fa-fw"></i>
        </button>
      </ng-template>
      <ng-template [ngIf]="!(row.paymentTemplate || row.ticketOrderId)" [ngIfElse]="withPermissions">
        <button
          class="btn btn-danger btn-sm"
          matTooltip="Storniert"
          (click)="updateStatus('canceled', 'status', rowIndex, row)"
        >
          <i class="fas fa-ban fa-fw"></i>
        </button>
      </ng-template>

      <ng-template #withPermissions>
        <button
          class="btn btn-danger btn-sm"
          matTooltip="Storniert"
          *ngxPermissionsOnly="['admin', 'manageReservationSettings']"
          (click)="updateStatus('canceled', 'status', rowIndex, row)"
        >
          <i class="fas fa-ban fa-fw"></i>
        </button>
      </ng-template>
    </ng-template>
    <!-- /On confirmed status -->

    <!-- On arrived status -->
    <ng-template [ngIf]="row.status === 'arrived' && row.showValue === false">
      <button
        class="btn btn-info btn-sm"
        matTooltip="Platziert"
        (click)="updateStatus('placed', 'status', rowIndex, row)"
      >
        <i class="fas fa-chair fa-fw"></i>
      </button>
      <button
        class="btn btn-success btn-sm"
        matTooltip="Fertig"
        (click)="updateStatus('finished', 'status', rowIndex, row)"
      >
        <i class="fas fa-check-double fa-fw"></i>
      </button>
    </ng-template>
    <!-- /On arrived status -->

    <!-- On placed status -->
    <ng-template [ngIf]="row.status === 'placed' && row.showValue === false">
      <button
        class="btn btn-success btn-sm"
        matTooltip="Fertig"
        (click)="updateStatus('finished', 'status', rowIndex, row)"
      >
        <i class="fas fa-check-double fa-fw"></i>
      </button>
    </ng-template>
    <!-- /On placed status -->

    <!-- On canceled status -->
    <ng-template [ngIf]="row.status === 'canceled' && row.showValue === false">
      <button
        class="btn btn-success btn-sm"
        matTooltip="Bestätigt"
        (click)="updateStatus('confirmed', 'status', rowIndex, row)"
      >
        <i class="fas fa-check-circle fa-fw"></i>
      </button>
    </ng-template>
    <!-- /On canceled status -->

    <!-- Show if status is custom -->
    <ng-template [ngIf]="row.showValue === true">
      <button
        class="btn btn-success btn-sm"
        matTooltip="Bestätigt"
        (click)="updateStatus('confirmed', 'status', rowIndex, row)"
      >
        <i class="fas fa-check-circle fa-fw"></i>
      </button>
      <ng-template [ngIf]="!(row.paymentTemplate || row.ticketOrderId)" [ngIfElse]="withPermissions">
        <button
          class="btn btn-danger btn-sm"
          matTooltip="Storniert"
          (click)="updateStatus('canceled', 'status', rowIndex, row)"
        >
          <i class="fas fa-ban fa-fw"></i>
        </button>
      </ng-template>

      <ng-template #withPermissions>
        <button
          class="btn btn-danger btn-sm"
          matTooltip="Storniert"
          *ngxPermissionsOnly="['admin', 'manageReservationSettings']"
          (click)="updateStatus('canceled', 'status', rowIndex, row)"
        >
          <i class="fas fa-ban fa-fw"></i>
        </button>
      </ng-template>
      <button
        class="btn btn-secondary btn-sm btn-arrived"
        matTooltip="Angekommen"
        (click)="updateStatus('arrived', 'status', rowIndex, row)"
      >
        <i class="fas fa-store-alt fa-fw"></i>
      </button>
      <button
        class="btn btn-info btn-sm"
        matTooltip="Platziert"
        (click)="updateStatus('placed', 'status', rowIndex, row)"
      >
        <i class="fas fa-chair fa-fw"></i>
      </button>
      <button
        class="btn btn-success btn-sm btn-finished"
        matTooltip="Fertig"
        (click)="updateStatus('finished', 'status', rowIndex, row)"
      >
        <i class="fas fa-check-double fa-fw"></i>
      </button>
      <button
        class="btn btn-danger btn-sm btn-noshow"
        matTooltip="No-Show"
        (click)="updateStatus('noShow', 'status', rowIndex, row)"
      >
        <i class="fas fa-eye-slash fa-fw"></i>
      </button>
    </ng-template>
    <!-- /Show if status is custom -->

    <ng-container *ngFor="let item of resStatusList; let i = index">
      <button
        class="btn btn-sm"
        [style.backgroundColor]="item.color"
        [matTooltip]="item.label"
        (click)="updateStatus(item.value, 'status', rowIndex, row)"
      >
        <i [class]="item.icon"></i>
      </button>
    </ng-container>

    <button class="btn btn-white btn-sm" (click)="editing[rowIndex + '-status'] = false">
      <i class="fas fa-times"></i>
    </button>
  </div>
</ng-template>

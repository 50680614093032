import { Component, OnInit, ViewChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { NgbModal, ModalDismissReasons, NgbDropdownConfig, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { AddTableComponent } from './add-table/add-table.component';
import { AddRoomComponent } from './add-room/add-room.component';
import { MatSnackBar } from '@angular/material';
import {
  GeneralSettings,
  Room,
  RoomTable,
  Shift,
  Timeplan,
  Notification,
  Alert,
  ReservationBookSettings,
  TextPresets,
  Question,
  PaymentTemplate
} from './reservation-settings.model';
import { AddShiftComponent } from './add-shift/add-shift.component';
import { AddResSeriesComponent } from './add-res-series/add-res-series.component';
import 'devextreme-intl';
import { locale, loadMessages } from 'devextreme/localization';
import deMessages from 'devextreme/localization/messages/de.json';
import { AddNotificationComponent } from './add-notification/add-notification.component';
import { AddAlertComponent } from './add-alert/add-alert.component';
import { ReservationService } from '../reservation.service';
import { DxSchedulerComponent } from 'devextreme-angular/ui/scheduler';
import { AuthenticationService, CredentialsService, resStatus } from '@app/core';
import { Options, LabelType } from 'ng5-slider';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Breadcrumb } from '@app/shared/breadcrumb/breadcrumb.component';
import { DxContextMenuComponent } from 'devextreme-angular/ui/context-menu';
import moment from 'moment';
moment.locale('de');
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { AddGlobalQuestionComponent } from './add-global-question/add-global-question.component';
import 'rxjs/add/operator/takeUntil';
import { Subject, from } from 'rxjs';
import { AddPaymentTemplateComponent } from './add-payment-template/add-payment-template.component';
import { TablePlanComponent } from './table-plan/table-plan.component';
import { Angular2CsvComponent } from 'angular2-csv';
import { RangesFooterNewComponent } from '../../../app/shared/ranges-footer-new/ranges-footer-new.component';
import { AddReservationAddOnTemplate } from './add-reservation-addon-template/add-reservation-addon-template.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddResMenuOrders } from './add-res-menu-orders/add-res-menu-orders.component';
import { AddTimeFilterComponent } from './add-time-filter/add-time-filter.component';
import { environment } from '@env/environment';
import { CopyShiftLayerComponent } from './copy-shift-layer/copy-shift-layer.component';
import { AddStatusComponent } from './add-status/add-status.component';
@Component({
  selector: 'app-reservation-settings',
  templateUrl: './reservation-settings.component.html',
  styleUrls: ['./reservation-settings.component.scss'],
  providers: [NgbDropdown, NgbDropdownConfig, ReservationService],
  encapsulation: ViewEncapsulation.None
})
export class ReservationSettingsComponent implements OnInit, OnDestroy {
  generalSettings: GeneralSettings = {
    isActive: true,
    isAutomatic: false,
    autoPlace: false,
    roomChoice: true,
    email: '',
    remindGuests: false,
    remindGuestsEmail: false,
    remindGuestsTextMessage: false,
    reminderDays: 1,
    reminderGroupSize: 1,
    reservationInactiveText: '',
    isGoogleReserve: false,
    showSubscribeNewsletter: true,
    reminderSMSText: '',
    reservationUntil: '',
    showStayTime: true,
    termsConditions: '',
    paymentTransactionFee: 0,
    showTranslation: false,
    defaultLang: 'de'
  };
  reservationBook: ReservationBookSettings = {
    timeInterval: 15,
    defaultStaytime: 60,
    requestStaff: false,
    confirmStatusChange: false,
    arrivedStatus: true,
    waitingStatus: false,
    showStatus: false,
    showWalkIns: true,
    startTime: 10,
    endTime: 24,
    showOccupancy: false,
    showTablePlan: false,
    includeShift: 0,
    showTimeFilter: false,
    showReservationId: false,
    phoneNoRequired: false,
    showCanceledRes: true,
    defaultTimeResBook: ''
  };
  textPresets: TextPresets = {
    acceptText: '',
    declineText: '',
    cancelText: '',
    reminderText: '',
    infoText: '',
    noMoreReservationMsg: ''
  };
  rooms: any;
  closeResult: string;
  shifts: Array<Shift>;
  tags: Array<any> = [];
  tag: any = {
    color: '#f44336',
    label: ''
  };
  channels: Array<Notification>;
  alerts: Array<Alert>;
  globalQuestions: any = [];
  paymentTemplates: any = [];
  reservationAddOnTemplates: any = [];
  resMenuOrders: any = [];
  resTimeFilter: any = [];
  resStatusList: any = [];
  allStatus: any[] = resStatus;
  timeplanData: [Timeplan];
  finalTimeSeries: any;
  currentDate: Date = new Date();
  showSMSServiceConfirmation: Date = new Date('2022-11-01');
  lowestTime: any;
  selectedCellData: any;
  tab: any;
  activeClient: any;
  showOldCalendar: boolean = false;
  alert: string = '';
  reminderTextError: string = '';
  times: any = [];
  stayTimeOptions: Options = {
    floor: 15,
    ceil: 600,
    step: 15,
    showTicks: true,
    showSelectionBar: true,
    translate: (value: number, label: LabelType): string => {
      value = value / 60; // Convert to hours
      const fractionalPart = value % 1;
      const mainPart = parseInt(value + '', 10);
      switch (label) {
        case LabelType.Floor:
          if (value === 0.25) {
            return '15 Minuten';
          } else if (value === 0.5) {
            return '30 Minuten';
          } else if (value === 0.75) {
            return '45 Minuten';
          } else if (value === 1) {
            return value + ' Stunde';
          } else {
            if (fractionalPart > 0) {
              const stunde = mainPart === 1 ? `${mainPart} Stunde` : `${mainPart} Stunden`;
              if (fractionalPart === 0.25) {
                return stunde + ' 15 Minuten';
              } else if (fractionalPart === 0.5) {
                return stunde + ' 30 Minuten';
              } else if (fractionalPart === 0.75) {
                return stunde + ' 45 Minuten';
              }
            } else {
              return value + ' Stunden';
            }
          }
          break;
        case LabelType.Ceil:
          if (value === 0.25) {
            return '15 Minuten';
          } else if (value === 0.5) {
            return '30 Minuten';
          } else if (value === 0.75) {
            return '45 Minuten';
          } else if (value === 1) {
            return value + ' Stunde';
          } else {
            if (fractionalPart > 0) {
              const stunde = mainPart === 1 ? `${mainPart} Stunde` : `${mainPart} Stunden`;
              if (fractionalPart === 0.25) {
                return stunde + ' 15 Minuten';
              } else if (fractionalPart === 0.5) {
                return stunde + ' 30 Minuten';
              } else if (fractionalPart === 0.75) {
                return stunde + ' 45 Minuten';
              }
            } else {
              return value + ' Stunden';
            }
          }
          break;
        case LabelType.Low:
          if (value === 0.25) {
            return '15 Minuten';
          } else if (value === 0.5) {
            return '30 Minuten';
          } else if (value === 0.75) {
            return '45 Minuten';
          } else if (value === 1) {
            return value + ' Stunde';
          } else {
            if (fractionalPart > 0) {
              const stunde = mainPart === 1 ? `${mainPart} Stunde` : `${mainPart} Stunden`;
              if (fractionalPart === 0.25) {
                return stunde + ' 15 Minuten';
              } else if (fractionalPart === 0.5) {
                return stunde + ' 30 Minuten';
              } else if (fractionalPart === 0.75) {
                return stunde + ' 45 Minuten';
              }
            } else {
              return value + ' Stunden';
            }
          }
          break;
        case LabelType.High:
          if (value === 0.25) {
            return '15 Minuten';
          } else if (value === 0.5) {
            return '30 Minuten';
          } else if (value === 0.75) {
            return '45 Minuten';
          } else if (value === 1) {
            return value + ' Stunde';
          } else {
            if (fractionalPart > 0) {
              const stunde = mainPart === 1 ? `${mainPart} Stunde` : `${mainPart} Stunden`;
              if (fractionalPart === 0.25) {
                return stunde + ' 15 Minuten';
              } else if (fractionalPart === 0.5) {
                return stunde + ' 30 Minuten';
              } else if (fractionalPart === 0.75) {
                return stunde + ' 45 Minuten';
              }
            } else {
              return value + ' Stunden';
            }
          }
          break;
        default:
          return value + '';
      }
    }
  };

  languageArray = [
    {
      lang: 'NL',
      value: 'nl',
      flag: `${environment.baseUrl}assets/img/flags-icons/nl.png`
    },
    {
      lang: 'GB',
      value: 'en',
      flag: `${environment.baseUrl}assets/img/flags-icons/uk.png`
    },
    {
      lang: 'DE',
      value: 'de',
      flag: `${environment.baseUrl}assets/img/flags-icons/de.png`
    },
    {
      lang: 'ES',
      value: 'es',
      flag: `${environment.baseUrl}assets/img/flags-icons/es.png`
    },
    {
      lang: 'FR',
      value: 'fr',
      flag: `${environment.baseUrl}assets/img/flags-icons/fr.png`
    }
  ];

  timesArray: any = [];
  @ViewChild(DxSchedulerComponent, { static: false }) scheduler: DxSchedulerComponent;
  // Context Menu
  contextMenuCellData: any;
  contextMenuAppointmentData: any;
  contextMenuTargetedAppointmentData: any;
  shiftContextMenuItems: any = [];
  @ViewChild('shiftContextMenu', { static: false }) shiftContextMenu: DxContextMenuComponent;
  @ViewChild(TablePlanComponent, { static: false }) tablePlan: TablePlanComponent;
  @ViewChild(Angular2CsvComponent, { static: false }) csvComponent: Angular2CsvComponent;

  // CSV Export
  reservations: any[] = [];
  csvReservations: any[] = [];
  csvOptions: any = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: [
      'ID',
      'Datum',
      'Uhrzeit (von)',
      'Uhrzeit (bis)',
      'Personen',
      'Tisch',
      'Name',
      'Telefon',
      'Email',
      'Hinweise',
      'Admin-Hinweise'
    ],
    showTitle: false,
    title: '',
    useBom: false,
    removeNewLines: true,
    keys: [
      'id',
      'date',
      'start',
      'end',
      'peopleCount',
      'table',
      'guestName',
      'guestPhone',
      'guestEmail',
      'guestNotes',
      'adminNotes'
    ]
  };
  reminderDaysOptions: Options = {
    floor: 1,
    ceil: 14,
    step: 1,
    showTicks: true,
    showSelectionBar: true,
    translate: (value: number): string => {
      return value + '';
    }
  };
  reminderGroupSizeOptions: Options = {
    floor: 1,
    ceil: 20,
    step: 1,
    showTicks: true,
    showSelectionBar: true,
    translate: (value: number): string => {
      return value + '';
    }
  };
  rangesFooter = RangesFooterNewComponent;
  timeSeriesDate: any = {
    begin: Date,
    end: Date
  };
  private ngUnsubscribe: Subject<any> = new Subject();
  smsSettingsFormGroup: FormGroup;
  editObservableSmsSettings: any;
  smsConfirmationSettings: any = {
    sendSMS: false
  };
  reservationUntilDays: any = [];
  phoneAssistantSettings: any;
  onlineResURL = '';
  paymentTransactionFee: number = 0;
  constructor(
    private modalService: NgbModal,
    public snackBar: MatSnackBar,
    private dropdownConfig: NgbDropdownConfig,
    private reservationService: ReservationService,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private credentialsService: CredentialsService,
    public formBuilder: FormBuilder
  ) {
    dropdownConfig.placement = 'top-right';
    loadMessages(deMessages);
    locale('de');
    this.getTimesArray();
    this.smsSettingsFormGroup = this.formBuilder.group({
      reservationActive: [0],
      gateway: [0],
      senderId: [''],
      confirmationText: ['', [Validators.maxLength(160)]],
      requestText: ['', [Validators.maxLength(160)]]
    });
  }

  ngOnInit() {
    this.getTimes();
    this.onlineResURL = environment.onlineResURL;
    this.authService
      .getActiveClient()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((clientData: any) => {
        this.activeClient = clientData;
        clientData.openingHours.forEach((time: any) => {
          if (!this.lowestTime || this.lowestTime.replace(':', '') < time.tFrom.replace(':', '')) {
            this.lowestTime = time.tFrom;
          }
        });
      });

    // Get query params for tab change
    this.activatedRoute.queryParams.takeUntil(this.ngUnsubscribe).subscribe(params => {
      if (params.tab) {
        this.tab = params.tab;
        this.route.navigate([], {
          queryParams: {
            tab: null
          },
          queryParamsHandling: 'preserve'
        });
      }
    });
    this.setReservationUntilDays();
    this.getSMSConfirmationSettings();
    this.getSettings();
    this.getNotificationChannels();
    // this.getRooms();
    this.getShifts();
    this.getQuestions();
    this.getPaymentTemplates();
    this.getReservationAddOnTemplates();
    this.getAllResMenuOrders();
    // this.getTimetable();
    this.getReservationBookSettings();
    this.getTags();
    this.getTimeTableSeries();
    this.getSmsSettings();
    this.getResTimeFilter();
    this.getPhoneAssistantSettings();
    this.getResStatus();
    // CSV
    // this.getAllReservations();
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setReservationUntilDays() {
    for (let i = 30; i <= 360; i += 10) {
      this.reservationUntilDays.push(i);
    }
  }
  getSMSConfirmationSettings() {
    this.reservationService
      .getSMSConfirmationSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((data: any) => {
        if (data && Object.keys(data).length > 0) {
          this.smsConfirmationSettings = data;
        }
      });
  }

  saveSMSSettings() {
    this.reservationService
      .saveSMSConfirmationSettings(this.smsConfirmationSettings)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((resp: any) => {
        this.snackBar.open('Einstellungen erfolgreich aktualisiert', '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
  }

  getTimeTableSeries(event: any = null) {
    /* New reservation series format*/
    this.timeSeriesDate.begin = event
      ? event.begin
      : moment()
          .startOf('week')
          .toDate();
    this.timeSeriesDate.end = event
      ? event.end
      : moment()
          .endOf('week')
          .toDate();
    let timeDifference = this.timeSeriesDate.end.getTime() - this.timeSeriesDate.begin.getTime();
    let differenceDays = Math.round(timeDifference / (1000 * 3600 * 24));

    if (differenceDays > 40) {
      this.timeSeriesDate.end = moment(this.timeSeriesDate.begin, 'DD-MM-YYYY')
        .add(40, 'days')
        .toDate();
    }
    this.getTimeTableCurrentWeek(this.timeSeriesDate.begin, this.timeSeriesDate.end);
  }
  getSettings() {
    this.reservationService
      .getSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((settings: any) => {
        this.generalSettings = settings;
        console.log(this.generalSettings);
        this.generalSettings.reservationUntil = this.generalSettings.reservationUntil
          ? this.generalSettings.reservationUntil
          : '';
        this.textPresets.acceptText = settings.acceptText;
        this.textPresets.declineText = settings.declineText;
        this.textPresets.cancelText = settings.cancelText;
        this.textPresets.reminderText = settings.reminderText;
        this.textPresets.infoText = settings.infoText;
        this.textPresets.noMoreReservationMsg = settings.noMoreReservationMsg;
        this.paymentTransactionFee = this.generalSettings.paymentTransactionFee;
      });
  }

  getReservationBookSettings() {
    this.reservationService
      .getReservationBookSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((settings: any) => {
        this.reservationBook = settings;
        console.log('this.reservationBook ===>', this.reservationBook);
      });
  }

  saveSettings() {
    if (this.reminderTextError) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.reservationService
      .saveSettings(this.generalSettings)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((resp: any) => {
        this.alert = '';
        this.snackBar.open('Einstellungen erfolgreich aktualisiert', '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
  }

  saveTextPresets() {
    // if (this.textPresets.acceptText && !this.textPresets.acceptText.includes('[zusatztext]')) {
    //   this.snackBar.open('Bitte fügen Sie Ihrem Bestätigungstext [zusatztext] hinzu.', '', {
    //     duration: 2000,
    //     panelClass: ['snackbar-error']
    //   });
    //   return;
    // }
    this.reservationService
      .saveSettings(this.textPresets)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((resp: any) => {
        const snackBarRef = this.snackBar.open('Textvorlagen erfolgreich gespeichert', '', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
      });
  }

  saveReservationBookSettings() {
    this.reservationService
      .saveReservationBookSettings(this.reservationBook)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((resp: any) => {
        this.reservationBook = resp;
      });
  }

  changeDefaultStaytime() {
    this.saveReservationBookSettings();
  }

  getRooms() {
    this.reservationService
      .getRooms()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((rooms: any) => {
        this.rooms = rooms;
      });
  }

  getShifts() {
    this.reservationService
      .getShifts()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((shifts: any) => {
        this.shifts = shifts;
        console.log('this.shifts  ===>', this.shifts);
      });
  }

  getQuestions() {
    this.reservationService
      .getQuestions()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((questions: any) => {
        this.globalQuestions = questions;
      });
  }

  getPaymentTemplates() {
    this.reservationService
      .getPaymentTemplates()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((paymentTemplates: any) => {
        this.paymentTemplates = paymentTemplates;
      });
  }
  getReservationAddOnTemplates() {
    this.reservationService
      .getReservationAddOnTemplates()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((reservationAddOnTemplates: any) => {
        this.reservationAddOnTemplates = reservationAddOnTemplates;
      });
  }

  getAllResMenuOrders() {
    this.reservationService
      .getAllResMenuOrders()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((resMenuOrders: any) => {
        this.resMenuOrders = resMenuOrders;
      });
  }
  getTimetable() {
    // this.timeplanData = this.reservationService.getAppointments();
    this.reservationService
      .getAppointments()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((timeplanData: any) => {
        timeplanData = timeplanData.filter((obj: any) => {
          return !(!obj.recurrenceRule && obj.recurrenceException);
        });
        timeplanData = timeplanData.map((obj: any) => {
          obj.startDate = new Date(obj.startDate.replace(/-/g, '/'));
          obj.endDate = new Date(obj.endDate.replace(/-/g, '/'));
          return obj;
        });
        this.timeplanData = timeplanData;
      });
  }

  selectedTabChange(e: any) {
    // if (e.index === 4) {
    //   this.scheduler.instance.repaint();
    // }
    // if (e.index === 2 && this.tablePlan) {
    //   this.tablePlan.getRooms();
    //   this.tablePlan.getAllTables();
    // }
  }

  addRoom() {
    const modalRef = this.modalService.open(AddRoomComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      receivedEntry.tables = [];
      this.rooms.push(receivedEntry);
      const snackBarRef = this.snackBar.open('Raum erfolgreich hinzugefügt', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  editRoom(room: Room, index: number) {
    const modalRef = this.modalService.open(AddRoomComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.editRoomData = { ...room };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      if (receivedEntry && receivedEntry !== 'delete') {
        this.rooms[index] = receivedEntry;
        const snackBarRef = this.snackBar.open('Raum erfolgreich gespeichert', 'Ok', {
          duration: 3000
        });
        modalRef.close();
      } else if (receivedEntry === 'delete') {
        this.rooms = this.rooms.filter((obj: any) => {
          return obj.id !== room.id;
        });
        modalRef.close();
      }
    });
  }

  addTable(room: Room) {
    const modalRef = this.modalService.open(AddTableComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.room = { ...room };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      room.tables.push(receivedEntry);
      modalRef.close();
    });
  }

  addCombination(room: Room) {
    const modalRef = this.modalService.open(AddTableComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.room = { ...room };
    modalRef.componentInstance.isCombined = true;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      room.tables.push(receivedEntry);
      modalRef.close();
    });
  }

  editTable(room: Room, table: RoomTable) {
    const modalRef = this.modalService.open(AddTableComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.room = { ...room };
    modalRef.componentInstance.editTableData = { ...table };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe(async (receivedEntry: any) => {
      table = receivedEntry;
      // Find room index to update correspondent table
      const roomIndex = await this.rooms
        .map((x: any) => {
          return x.id;
        })
        .indexOf(room.id);
      // Find table index to update correspondent table
      const tableIndex = await this.rooms[roomIndex].tables
        .map((x: any) => {
          return x.id;
        })
        .indexOf(table.id);
      // Update table
      this.rooms[roomIndex].tables[tableIndex] = receivedEntry;
      const snackBarRef = this.snackBar.open('Tisch erfolgreich gespeichert', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  deleteTable(tableId: number) {
    this.reservationService
      .deleteTable(tableId)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((response: any) => {
        // Delete from array
        // Find room index to update correspondent table
        const roomIndex = this.rooms
          .map((x: any) => {
            return x.id;
          })
          .indexOf(response.roomId);
        this.rooms[roomIndex].tables = this.rooms[roomIndex].tables.filter((obj: any) => {
          return obj.id !== response.id;
        });

        const snackBarRef = this.snackBar.open('Tisch erfolgreich gelöscht', 'Ok', {
          duration: 3000
        });
      });
  }

  addGlobalQuestion() {
    const modalRef = this.modalService.open(AddGlobalQuestionComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.shifts = { ...this.shifts };
    modalRef.componentInstance.tags = { ...this.tags };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.globalQuestions.push(receivedEntry);
      const snackBarRef = this.snackBar.open('Abfrage erfolgreich erstellt', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  editGlobalQuestion(question: Question, index: number) {
    const modalRef = this.modalService.open(AddGlobalQuestionComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.editQuestionData = { ...question };
    modalRef.componentInstance.shifts = { ...this.shifts };
    modalRef.componentInstance.tags = { ...this.tags };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      if (receivedEntry) {
        this.globalQuestions[index] = receivedEntry;
        const snackBarRef = this.snackBar.open('Abfrage erfolgreich gespeichert', 'Ok', {
          duration: 3000
        });
        modalRef.close();
      }
    });
  }

  deleteGlobalQuestion(questionId: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Frage löschen';
    modalRef.componentInstance.message = `Willst du diese Frage löschen? Alle zugehörigen Antworten in den Reservierungen gehen verloren.`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.reservationService
            .deleteQuestion(questionId)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((response: any) => {
              this.globalQuestions = this.globalQuestions.filter((obj: any) => {
                return obj.id !== questionId;
              });
              const snackBarRef = this.snackBar.open('Abfrage erfolgreich gelöscht', 'Ok', {
                duration: 3000
              });
            });
        }
      },
      () => {}
    );
  }

  addPaymentTemplate() {
    const modalRef = this.modalService.open(AddPaymentTemplateComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.clientData = { ...this.activeClient };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.paymentTemplates.push(receivedEntry);
      const snackBarRef = this.snackBar.open('Anzahlungsvorlage erfolgreich erstellt', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  editPaymentTemplate(paymentTemplate: PaymentTemplate, index: number) {
    const modalRef = this.modalService.open(AddPaymentTemplateComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.clientData = { ...this.activeClient };
    modalRef.componentInstance.editPaymentTemplateData = { ...paymentTemplate };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      if (receivedEntry) {
        this.paymentTemplates[index] = receivedEntry;
        const snackBarRef = this.snackBar.open('Anzahlungsvorlage erfolgreich gespeichert', 'Ok', {
          duration: 3000
        });
        modalRef.close();
      }
    });
  }

  deletePaymentTemplate(templateId: number) {
    this.reservationService
      .deletePaymentTemplate(templateId)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((response: any) => {
        this.paymentTemplates = this.paymentTemplates.filter((obj: any) => {
          return obj.id !== templateId;
        });
        const snackBarRef = this.snackBar.open('Anzahlungsvorlage erfolgreich gelöscht', 'Ok', {
          duration: 3000
        });
      });
  }

  getConnectedList(): any[] {
    return this.rooms.map((x: any) => `${x.id}`);
  }

  async dropShift(event: CdkDragDrop<string[]>) {
    await moveItemInArray(this.shifts, event.previousIndex, event.currentIndex);
    this.reservationService
      .setShiftOrder(this.shifts)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((receivedEntry: any) => {
        this.snackBar.open('Reihenfolge wurde erfolgreich gespeichert', '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
  }

  async dropItem(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      await moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.reservationService
        .saveTableOrder(event.container.data, +event.container.id)
        .takeUntil(this.ngUnsubscribe)
        .subscribe((receivedEntry: any) => {});
      /*
      this.reservationService
        .setTableOrder(event.item.data.id, event.item.data.roomId, event.previousIndex, event.currentIndex)
        .subscribe((receivedEntry: any) => {});
        */
    } else {
      await transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      await this.reservationService
        .saveTableOrder(event.container.data, +event.container.id)
        .takeUntil(this.ngUnsubscribe)
        .subscribe((receivedEntry: any) => {});
      // this.reservationService.saveTableOrder(event.previousContainer.data).subscribe((receivedEntry: any) => {});

      /*
      this.reservationService
        .setTableOrder(event.item.data.id, +event.container.id, event.previousIndex, event.currentIndex)
        .subscribe((receivedEntry: any) => {});
        */
    }
  }

  addShift() {
    const copyShiftModalRef = this.modalService.open(CopyShiftLayerComponent, { windowClass: 'onboarding-modal' });
    copyShiftModalRef.componentInstance.shifts = this.shifts;
    copyShiftModalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry == 'createNew') {
        const modalRef = this.modalService.open(AddShiftComponent, { size: 'lg' });
        modalRef.componentInstance.generalSettings = this.generalSettings;
        modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
          this.shifts.push(receivedEntry);
          const snackBarRef = this.snackBar.open('Vorlage erfolgreich hinzugefügt', 'Ok', {
            duration: 3000
          });
          modalRef.close();
        });
      } else {
        this.shifts.push(receivedEntry);
        const snackBarRef = this.snackBar.open('Vorlage erfolgreich hinzugefügt', 'Ok', {
          duration: 3000
        });
        copyShiftModalRef.close();
      }
    });
  }

  editShift(shift: Shift, index: number) {
    const modalRef = this.modalService.open(AddShiftComponent, { size: 'lg' });
    modalRef.componentInstance.editShiftData = { ...shift };
    modalRef.componentInstance.generalSettings = this.generalSettings;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.shifts[index] = receivedEntry;
      const snackBarRef = this.snackBar.open('Vorlage erfolgreich gespeichert', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  getNotificationChannels() {
    this.reservationService
      .getNotificationChannels()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((channels: any) => {
        this.channels = channels;
      });
  }

  addNotificationChannel() {
    const modalRef = this.modalService.open(AddNotificationComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.reservationService
        .addNotificationChannel(receivedEntry)
        .takeUntil(this.ngUnsubscribe)
        .subscribe((response: any) => {
          this.channels.push(receivedEntry);
          const snackBarRef = this.snackBar.open('Kanal erfolgreich hinzugefügt', 'Ok', {
            duration: 3000
          });
          modalRef.close();
        });
    });
  }

  editNotificationChannel(channel: Notification, index: number) {
    const modalRef = this.modalService.open(AddNotificationComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.editChannelData = { ...channel };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.reservationService
        .editNotificationChannel(receivedEntry)
        .takeUntil(this.ngUnsubscribe)
        .subscribe((response: any) => {
          this.channels[index] = receivedEntry;
          const snackBarRef = this.snackBar.open('Kanal erfolgreich gespeichert', 'Ok', {
            duration: 3000
          });
          modalRef.close();
        });
      modalRef.close();
    });
  }

  deleteNotificationChannel(channelId: number) {
    this.reservationService
      .deleteNotificationChannel(channelId)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(() => {
        // Delete from array
        this.channels = this.channels.filter((obj: any) => {
          return obj.id !== channelId;
        });
        const snackBarRef = this.snackBar.open('Vorlage erfolgreich gelöscht', 'Ok', {
          duration: 3000
        });
      });
  }

  onAppointmentContextMenu(e: any) {
    this.contextMenuAppointmentData = e.appointmentData;
    this.contextMenuTargetedAppointmentData = e.targetedAppointmentData;

    this.onShiftContextMenuShowing(e);
  }

  onAppointmentClick(e: any) {
    e.cancel = true;
  }

  // Called before the context menu for a shift is opened
  onShiftContextMenuShowing(e: any) {
    const basicMenuItems: any = [
      {
        text: 'Bearbeiten',
        onItemClick: () =>
          this.scheduler.instance.showAppointmentPopup(
            this.contextMenuAppointmentData,
            false,
            this.contextMenuTargetedAppointmentData
          )
      }
    ];
    if (!e.appointmentData.isBlocked) {
      basicMenuItems.push({
        text: 'Blocken',
        onItemClick: () =>
          this.blockTimeTableEntry(this.contextMenuAppointmentData, this.contextMenuTargetedAppointmentData)
      });
      basicMenuItems.push({
        text: 'Löschen',
        onItemClick: () => this.deleteTimeTableEntry(this.contextMenuAppointmentData)
      });
    } else {
      basicMenuItems.push({
        text: 'Entblocken',
        onItemClick: () => this.unblockTimeTableEntry(this.contextMenuAppointmentData)
      });
    }

    this.shiftContextMenu.instance.option('items', basicMenuItems);
  }

  onContextMenuItemClick(e: any) {
    e.itemData.onItemClick(e.itemData);
  }

  onAppointmentFormOpening(data: any) {
    const that = this,
      form = data.form;
    const startDate = data.appointmentData.startDate;
    let allItems = form.option('items');
    const formData = form.option('formData');
    allItems = allItems.filter((item: any, index: number) => {
      return item.dataField === 'recurrenceRule';
    });

    const min = new Date(startDate);
    min.setHours(this.reservationBook.startTime);
    min.setMinutes(0);
    min.setSeconds(0);
    min.setMilliseconds(0);
    const max = new Date(startDate);
    max.setHours(this.reservationBook.endTime);
    max.setMinutes(1);
    max.setSeconds(0);
    max.setMilliseconds(0);

    const endTime = {
      name: 'endTime',
      dataField: 'endDate',
      label: {
        text: 'Endzeit'
      },
      editorType: 'dxDateBox',
      editorOptions: {
        type: 'time',
        min: min,
        max: max,
        interval: 30
      },
      validationRules: [
        {
          type: 'required'
        }
      ]
    };

    allItems.unshift(endTime);

    const startTime = {
      name: 'startTime',
      dataField: 'startDate',
      label: {
        text: 'Startzeit'
      },
      editorType: 'dxDateBox',
      editorOptions: {
        type: 'time',
        min: min,
        max: max,
        interval: 30
      },
      validationRules: [
        {
          type: 'required'
        }
      ]
    };

    allItems.unshift(startTime);

    const shiftSelect = {
      colSpan: 2,
      label: {
        text: 'Vorlage'
      },
      editorType: 'dxSelectBox',
      dataField: 'shift',
      editorOptions: {
        items: [...that.shifts],
        displayExpr: function(item: any) {
          const description = item && item.description ? ' (' + item.description + ')' : '';
          return item && item.name + description;
        },
        valueExpr: 'id',
        onValueChanged: function(args: any) {
          // movieInfo = that.getMovieById(args.value);
        }.bind(this)
      },
      validationRules: [
        {
          type: 'required'
        }
      ]
    };
    allItems.unshift(shiftSelect);

    form.option({
      items: allItems,
      formData: formData
    });
  }

  parseTime(time: string) {
    const d = new Date();
    const split = time.split(':');
    d.setHours(+split[0]);
    d.setMinutes(+split[1]);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  }

  onAppointmentFormCreated(data: any) {
    const that = this,
      form = data.form;
    const startDate = data.appointmentData.startDate;
    let allItems = form.option('items');
    const formData = form.option('formData');
    allItems = allItems.filter((item: any, index: number) => {
      return item.dataField === 'recurrenceRule' || item.dataField === 'startDate' || item.dataField === 'endDate';
    });
    const shiftSelect = {
      colSpan: 2,
      label: {
        text: 'Vorlage'
      },
      editorType: 'dxSelectBox',
      dataField: 'shift',
      editorOptions: {
        items: [...that.shifts],
        displayExpr: 'name',
        valueExpr: 'id',
        onValueChanged: function(args: any) {
          // movieInfo = that.getMovieById(args.value);
        }.bind(this)
      },
      validationRules: [
        {
          type: 'required'
        }
      ]
    };
    allItems.unshift(shiftSelect);
    form.option({
      items: allItems,
      formData: formData
    });
  }

  getTimesArray() {
    let tStart = 0;
    const interval = 60;
    for (let i = 0; tStart <= 24 * 60; i++) {
      const hh = Math.floor(tStart / 60); // getting hours of day in 0-24 format
      const mm = tStart % 60; // getting minutes of the hour in 0-55 format
      const timeObj = {
        time: ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2),
        value: hh
      };
      this.timesArray[i] = timeObj;
      tStart = tStart + interval;
    }
  }

  onAppointmentAdded(data: any) {}

  onAppointmentUpdating(data: any) {
    if (data.oldData.isBlocked && data.newData.isBlocked) {
      console.log(980, data);
      data.cancel = true;
      return false;
    }
    const shift = this.shifts.find((x: any) => x.id === data.newData.shift);

    data.cancel = this.reservationService.editTimeTableEntry(data.newData).then((res: any) => {
      this.getTimetable();
      data.newData.name = shift.name;
      data.newData.color = shift.color;
      const snackBarRef = this.snackBar.open('Zeit erfolgreich geändert', 'Ok', {
        duration: 3000
      });
      return !res;
    });
  }

  onAppointmentAdding(data: any) {
    const entryData = {
      shiftId: data.appointmentData.shift,
      startDate: data.appointmentData.startDate,
      endDate: data.appointmentData.endDate,
      recurrenceRule: data.appointmentData.recurrenceRule
    };
    const shift = this.shifts.find((x: any) => x.id === data.appointmentData.shift);

    data.cancel = this.reservationService.addTimeTableEntry(data.appointmentData).then((res: any) => {
      data.appointmentData.id = res.id;
      data.appointmentData.name = shift.name;
      data.appointmentData.color = shift.color;
      const snackBarRef = this.snackBar.open('Zeit erfolgreich hinzugefügt', 'Ok', {
        duration: 3000
      });
      return !res;
    });
  }

  onAppointmentDeleted(data: any) {
    this.reservationService
      .deleteTimeTableEntry(data.appointmentData.id)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(() => {
        data.component._appointmentTooltip.hide();
        const snackBarRef = this.snackBar.open('Zeit erfolgreich gelöscht', 'Ok', {
          duration: 3000
        });
        this.getTimetable();
      });
  }

  getTags() {
    this.reservationService
      .getTags()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((tags: any) => {
        this.tags = tags;
      });
  }

  addTag() {
    this.reservationService
      .addTag(this.tag)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((response: any) => {
        this.tags.push({ ...this.tag });
        const snackBarRef = this.snackBar.open('Tag erfolgreich hinzugefügt', 'Ok', {
          duration: 3000
        });
      });
  }

  deleteTag(tagId: number) {
    this.reservationService
      .deleteTag(tagId)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(() => {
        // Delete from array
        this.tags = this.tags.filter((obj: any) => {
          return obj.id !== tagId;
        });
        const snackBarRef = this.snackBar.open('Tag erfolgreich gelöscht', 'Ok', {
          duration: 3000
        });
      });
  }

  changeTagColor(event: any) {
    this.tag.color = event;
  }

  deleteShift(shiftId: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Vorlage löschen';
    modalRef.componentInstance.message = `Sind Sie sicher dass Sie die Vorlage unwiederruflich löschen möchten?`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.reservationService
            .deleteShift(shiftId)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(() => {
              // Delete from array
              this.shifts = this.shifts.filter((obj: any) => {
                return obj.id !== shiftId;
              });
              const snackBarRef = this.snackBar.open('Vorlage erfolgreich gelöscht', 'Ok', {
                duration: 3000
              });
            });
        }
      },
      () => {}
    );
  }

  editTimeTableEntry(entry: any) {
    this.scheduler.instance.showAppointmentPopup(this.getDataObj(entry), false);
  }

  blockTimeTableEntry(originalShift: any, shift: any) {
    const recurrenceExceptionArray = originalShift.recurrenceException
      ? originalShift.recurrenceException.split(',')
      : [];
    recurrenceExceptionArray.push(moment(shift.startDate).format('YYYYMMDDTHHmmss') + 'Z');
    originalShift.recurrenceException = recurrenceExceptionArray.join(',');

    shift.isBlocked = true;
    shift.recurrenceRule = null;
    this.reservationService.addTimeTableEntry(shift).then((res: any) => {
      this.reservationService.editTimeTableEntry(originalShift).then((result: any) => {
        this.getTimetable();
        const snackBarRef = this.snackBar.open('Zeit erfolgreich geblockt', 'Ok', {
          duration: 3000
        });
        return !res;
      });
    });
  }

  unblockTimeTableEntry(shift: any) {
    shift.isBlocked = false;
    this.reservationService.unblockTimeTableEntry(shift).then((res: any) => {
      this.getTimetable();
      const snackBarRef = this.snackBar.open('Zeit erfolgreich entgeblockt', 'Ok', {
        duration: 3000
      });
      return !res;
    });
  }

  deleteTimeTableEntry(shift: any) {
    this.scheduler.instance.deleteAppointment(this.getDataObj(shift));
  }

  getDataObj(objData: any) {
    for (let i = 0; i < this.timeplanData.length; i++) {
      this.timeplanData[i].startDate = new Date(this.timeplanData[i].startDate);
      objData.startDate = new Date(objData.startDate);
      if (this.timeplanData[i].startDate.getTime() === objData.startDate.getTime()) {
        return this.timeplanData[i];
      }
    }
    return null;
  }

  blockTimetableEntry(shift: any) {}

  stopPropagation(e: any) {
    e.stopPropagation();
  }

  selectedCellDataChange(event: any) {
    this.selectedCellData = event;
  }

  mouseup() {
    /*
    // Just a hack to open form on drag release. Should be optimized with a better solution!
    if (this.selectedCellData && this.selectedCellData.length > 1) {
      this.scheduler.instance.showAppointmentPopup({
        startDate: this.selectedCellData[0].startDate,
        endDate: this.selectedCellData[this.selectedCellData.length - 1].endDate
      });
    }
    this.selectedCellData = [];
    */
  }

  isClosed(cellData: any) {
    if (cellData && this.activeClient && this.activeClient.specialOpeningHours) {
      // cellData.startDate;
      const foundObj = this.activeClient.specialOpeningHours.find((oh: any) =>
        moment(cellData.startDate).isBetween(oh.fromDate, oh.toDate)
      );
      if (!foundObj) {
        return false;
      }
      if (foundObj.closed && !foundObj.from1 && !foundObj.to1 && !foundObj.from2 && !foundObj.to2) {
        return true;
      }
      const from1 = foundObj.from1.replace(':', '');
      const to1 = foundObj.to1.replace(':', '');
      const from2 = foundObj.from2.replace(':', '');
      const to2 = foundObj.to2.replace(':', '');
      const time = moment(cellData.startDate)
        .format('HH:mm')
        .replace(':', '');

      if (foundObj.from1 && foundObj.to1 && !foundObj.from2 && !foundObj.to2 && (time < from1 || time > to1)) {
        return true;
      }
      if (
        foundObj.from1 &&
        foundObj.to1 &&
        foundObj.from2 &&
        foundObj.to2 &&
        (time < from1 || (time > to1 && time < from2) || time > to2)
      ) {
        return true;
      }
      return false;
    }
  }

  downloadCsv() {
    this.getAllReservations().then(() => {
      setTimeout(() => {
        this.csvComponent.onDownload();
      }, 0);
    });
  }

  async getAllReservations() {
    const endDate = new Date(2030, 12, 31);
    await this.reservationService
      .getAllReservations(this.currentDate, endDate, 0, 9999999)
      .toPromise()
      .then((data: any) => {
        this.reservations = data.reservations;
        this.reservations.sort(function(a: any, b: any) {
          const keyA = new Date(a.reservedFor),
            keyB = new Date(b.reservedFor);
          if (keyA < keyB) {
            return -1;
          }
          if (keyA > keyB) {
            return 1;
          }
          return 0;
        });

        this.reservations.forEach((reservation: any) => {
          const tempReservation: any = {};
          tempReservation.id = reservation.id;
          tempReservation.date = moment(reservation.startDate).format('DD.MM.YYYY');
          tempReservation.start = moment(reservation.startDate).format('HH:mm');
          tempReservation.end = moment(reservation.endDate).format('HH:mm');
          tempReservation.peopleCount = reservation.peopleCount;
          if (reservation.tables.length) {
            tempReservation.table =
              (reservation.tables[0].room ? reservation.tables[0].room.name + ': ' : '') +
              ' Tisch ' +
              reservation.tables[0].name;
          } else {
            tempReservation.table = '-';
          }
          tempReservation.guestName = reservation.guestData ? reservation.guestData.name : '';
          tempReservation.guestEmail = reservation.guestData ? reservation.guestData.email : '';
          tempReservation.guestPhone = reservation.guestData ? reservation.guestData.phone : '';
          tempReservation.guestNotes =
            reservation.guestData && reservation.guestData.notes ? reservation.guestData.notes : '';
          tempReservation.adminNotes = reservation.notes || '';

          this.csvReservations.push(tempReservation);
        });
      });
  }

  /* New reservation series format*/
  addReservationSeries() {
    const modalRef = this.modalService.open(AddResSeriesComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.shifts = this.shifts;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.reservationService.addTimeTableSeries(receivedEntry).then((response: any) => {
        const snackBarRef = this.snackBar.open('Zeit erfolgreich hinzugefügt.', 'Ok', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
        modalRef.close();
        this.getTimeTableSeries({ begin: this.timeSeriesDate.begin, end: this.timeSeriesDate.end });
      });
    });
  }

  async getTimeTableCurrentWeek(startDateNew: any, endDateNew: any) {
    const startDate = new Date(startDateNew);
    const endDate = new Date(endDateNew);
    const dateAfter10years = new Date();
    dateAfter10years.setFullYear(dateAfter10years.getFullYear() + 10);
    this.finalTimeSeries = [];
    this.reservationService
      .getTimeTableCurrentWeek(startDate, endDate)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((timeplanData: any) => {
        // timeplanData = timeplanData.filter((obj: any) => {
        //   return !(!obj.recurrenceRule && obj.recurrenceException);
        // });
        timeplanData = timeplanData.map((obj: any) => {
          obj.startDate = new Date(obj.startDate.replace(/-/g, '/'));
          obj.endDate = new Date(obj.endDate.replace(/-/g, '/'));
          let recurrenceRuleArray = obj.recurrenceRule ? obj.recurrenceRule.split(';') : [];
          let recurrenceRule: any[] = [];
          recurrenceRuleArray.map((item: any) => {
            let itemData = item.split('=');
            recurrenceRule[itemData[0]] = itemData[1];
          });
          let recurrenceExceptionArray = obj.recurrenceException ? obj.recurrenceException.split(',') : [];
          recurrenceExceptionArray = recurrenceExceptionArray.map((item: any) => {
            return moment(item).format('DD.MMM YYYY');
          });
          obj.recurrenceRuleFinal = recurrenceRule;
          obj.recurrenceExceptionArray = recurrenceExceptionArray;
          return obj;
        });
        if (timeplanData && timeplanData.length > 0) {
          /*Calculate week days*/
          const days = [
            {
              day: 'Sonntag',
              value: 'SU'
            },
            {
              day: 'Montag',
              value: 'MO'
            },
            {
              day: 'Dienstag',
              value: 'TU'
            },
            {
              day: 'Mittwoch',
              value: 'WE'
            },
            {
              day: 'Donnerstag',
              value: 'TH'
            },
            {
              day: 'Freitag',
              value: 'FR'
            },
            {
              day: 'Samstag',
              value: 'SA'
            }
          ];
          const weekDays = [];
          let dt = startDate;
          while (dt <= endDate) {
            weekDays.push(new Date(dt));
            dt.setDate(dt.getDate() + 1);
          }
          const finalTimeSeries: any = [];
          const finalSeriesBlocked: any = [];
          let isBlockedValue = false;
          timeplanData = weekDays.map((obj: any) => {
            let date = new Date(obj);
            let dateShow = moment(date).format('DD. MMM YYYY');
            let dayNameValue = days[date.getDay()];
            finalTimeSeries[dayNameValue.day + ', ' + dateShow] = [];
            timeplanData.map((item: any) => {
              let seriesDate = new Date(item.startDate);
              let endDateValue = item.recurrenceRuleFinal.UNTIL
                ? moment(item.recurrenceRuleFinal.UNTIL).toDate()
                : moment(dateAfter10years).toDate();
              item.currentDate = date;
              if (
                seriesDate.setHours(0, 0, 0, 0) <= date.setHours(0, 0, 0, 0) &&
                item.isBlocked == 0 &&
                item.parentId == 0 &&
                endDateValue.setHours(0, 0, 0, 0) >= date.setHours(0, 0, 0, 0)
              ) {
                isBlockedValue =
                  item.recurrenceExceptionArray &&
                  item.recurrenceExceptionArray.indexOf(moment(date).format('DD.MMM YYYY')) > -1
                    ? true
                    : false;

                if (item.recurrenceRuleFinal && Object.keys(item.recurrenceRuleFinal).length > 0) {
                  if (item.recurrenceRuleFinal.FREQ == 'DAILY') {
                    finalTimeSeries[dayNameValue.day + ', ' + dateShow].push(
                      this.createSeriesDetail(item, isBlockedValue)
                    );
                  } else if (item.recurrenceRuleFinal.FREQ == 'WEEKLY') {
                    if (item.recurrenceRuleFinal.BYDAY && item.recurrenceRuleFinal.BYDAY.includes(dayNameValue.value)) {
                      finalTimeSeries[dayNameValue.day + ', ' + dateShow].push(
                        this.createSeriesDetail(item, isBlockedValue)
                      );
                    }
                  }
                } else {
                  if (seriesDate.toDateString() == date.toDateString()) {
                    finalTimeSeries[dayNameValue.day + ', ' + dateShow].push(
                      this.createSeriesDetail(item, isBlockedValue)
                    );
                  }
                }
              } else if (
                seriesDate.setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0) &&
                item.isBlocked != 0 &&
                item.parentId != 0
              ) {
                isBlockedValue = true;
                const parent = timeplanData.find((x: any) => x.id === item.parentId);
                finalSeriesBlocked.push(this.createSpecialSeriesDetail(item, isBlockedValue, parent));
              }
            });
          });
          let obj: any = [];
          Object.entries(finalTimeSeries).map((item: any, key: any) => {
            obj[key] = item;
            this.finalTimeSeries = obj;
          });
          if (finalSeriesBlocked.length > 0) {
            this.finalTimeSeries.push(['Blocked', finalSeriesBlocked]);
          }
          console.log('return', this.finalTimeSeries);
        }
      });
  }

  createSeriesDetail(item: any, isBlockedValue: boolean) {
    let finalItem: any = [];
    finalItem['value'] = moment(item.startDate).format('HH:mm') + ' -' + moment(item.endDate).format('HH:mm') + ' Uhr';
    finalItem['color'] = item.color;
    finalItem['shiftName'] = item.name;
    finalItem['isRWGShift'] = item.isRWGShift;
    finalItem['endingAt'] = item.recurrenceRuleFinal.UNTIL
      ? moment(item.recurrenceRuleFinal.UNTIL).format('DD.MMM YYYY')
      : '';
    finalItem['details'] = item;
    finalItem['currentDate'] = item.currentDate;
    finalItem['type'] = isBlockedValue ? 'blocked' : '';
    finalItem['shiftDescription'] = item.description;
    return finalItem;
  }
  createSpecialSeriesDetail(item: any, isBlockedValue: boolean, parent: any = []) {
    let finalItem: any = [];
    finalItem['value'] =
      moment(item.startDate).format('DD.MMM HH:mm') + ' -' + moment(item.endDate).format('DD.MMM HH:mm') + ' Uhr';
    finalItem['color'] = item.color;
    finalItem['shiftName'] = item.name;
    finalItem['isRWGShift'] = item.isRWGShift;
    finalItem['details'] = item;
    finalItem['currentDate'] = item.currentDate;
    finalItem['type'] = isBlockedValue ? 'blocked' : '';
    finalItem['shiftDescription'] = item.description;
    // finalItem['endingAt'] =
    //   parent.recurrenceExceptionArray && parent.recurrenceExceptionArray.length
    //     ? parent.recurrenceExceptionArray[parent.recurrenceExceptionArray.length - 1]
    //     : '';
    return finalItem;
  }

  editResTime(data: any, type: any, todayDate: any) {
    if (type) {
      this.blockResTimeEdit(data, todayDate);
      return;
    }
    const modalRef = this.modalService.open(AddResSeriesComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.shifts = this.shifts;
    modalRef.componentInstance.editResTimeData = { ...data, todayDate };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      if (receivedEntry.dayEditOption) {
        let blockedData = { ...receivedEntry };
        blockedData.startTime = receivedEntry.oldStartTime;
        blockedData.endTime = receivedEntry.oldEndTime;
        this.reservationService.blockTimeTableSeries(blockedData).then((response: any) => {
          this.reservationService.addTimeTableSeries(receivedEntry).then((response: any) => {
            const snackBarRef = this.snackBar.open('Uhrzeit erfolgreich aktualisiert.', 'Ok', {
              duration: 3000,
              panelClass: ['snackbar-success']
            });
            modalRef.close();
            this.getTimeTableSeries({ begin: this.timeSeriesDate.begin, end: this.timeSeriesDate.end });
          });
        });
      } else {
        this.reservationService.editTimeTableSeries(receivedEntry).then((response: any) => {
          const snackBarRef = this.snackBar.open('Uhrzeit erfolgreich aktualisiert.', 'Ok', {
            duration: 3000,
            panelClass: ['snackbar-success']
          });
          modalRef.close();
          this.getTimeTableSeries({ begin: this.timeSeriesDate.begin, end: this.timeSeriesDate.end });
        });
      }
    });
  }
  deleteResTime(id: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Zeit löschen';
    modalRef.componentInstance.message = `Sind Sie sicher, dass Sie diese Reservierungs-Serie löschen möchten?`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.reservationService
            .deleteTimeTableSeries(id)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(response => {
              if (response) {
                this.snackBar.open('Zeit erfolgreich gelöscht', '', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
                this.getTimeTableSeries({ begin: this.timeSeriesDate.begin, end: this.timeSeriesDate.end });
              }
            });
        }
      },
      () => {}
    );
  }
  blockResTime(data: any, todayDate: any) {
    const modalRef = this.modalService.open(AddResSeriesComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.shifts = this.shifts;
    modalRef.componentInstance.editResTimeData = { ...data, blocked: true, todayDate: todayDate };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.reservationService.blockTimeTableSeries(receivedEntry).then((response: any) => {
        const snackBarRef = this.snackBar.open('Zeit erfolgreich geblockt.', 'Ok', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
        modalRef.close();
        this.getTimeTableSeries({ begin: this.timeSeriesDate.begin, end: this.timeSeriesDate.end });
      });
    });
  }
  blockResTimeEdit(data: any, todayDate: any) {
    const modalRef = this.modalService.open(AddResSeriesComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.shifts = this.shifts;
    modalRef.componentInstance.editResTimeData = {
      ...data,
      blocked: true,
      todayDate: todayDate,
      singleBlockDate: true
    };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.reservationService.blockTimeTableSeriesEdit(receivedEntry).then((response: any) => {
        const snackBarRef = this.snackBar.open('Zeit erfolgreich geblockt.', 'Ok', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
        modalRef.close();
        this.getTimeTableSeries({ begin: this.timeSeriesDate.begin, end: this.timeSeriesDate.end });
      });
    });
  }
  unBlockResTime(data: any) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Zeit entsperren';
    modalRef.componentInstance.message = `Möchten Sie diese Reservierungszeit wieder aktivieren?`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.reservationService.unBlockTimeTableSeries(data).then((response: any) => {
            if (response) {
              this.snackBar.open('Zeit erfolgreich entgeblockt.', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
              this.getTimeTableSeries({ begin: this.timeSeriesDate.begin, end: this.timeSeriesDate.end });
            }
          });
        }
      },
      () => {}
    );
  }
  addReservationAddOnTemplate() {
    const modalRef = this.modalService.open(AddReservationAddOnTemplate, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.clientData = { ...this.activeClient };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.reservationAddOnTemplates.push(receivedEntry);
      const snackBarRef = this.snackBar.open('Add-On erfolgreich erstellt', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  editReservationAddOnTemplate(template: any, index: number) {
    const modalRef = this.modalService.open(AddReservationAddOnTemplate, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.clientData = { ...this.activeClient };
    modalRef.componentInstance.editReservationAddOnTemplate = { ...template };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      if (receivedEntry) {
        this.reservationAddOnTemplates[index] = receivedEntry;
        const snackBarRef = this.snackBar.open('Add-On erfolgreich gespeichert', 'Ok', {
          duration: 3000
        });
        modalRef.close();
      }
    });
  }

  deleteReservationAddOnTemplate(templateId: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Add-On löschen';
    modalRef.componentInstance.message = `Bist du sicher, dass du dieses Add-On löschen möchtest?`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.reservationService
            .deleteReservationAddOnTemplate(templateId)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((response: any) => {
              this.reservationAddOnTemplates = this.reservationAddOnTemplates.filter((obj: any) => {
                return obj.id !== templateId;
              });
              const snackBarRef = this.snackBar.open('Add-On erfolgreich gelöscht', 'Ok', {
                duration: 3000
              });
            });
        }
      },
      () => {}
    );
  }

  getSmsSettings() {
    this.reservationService
      .getSmsSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((smsSettings: any) => {
        if (smsSettings) {
          this.smsSettingsFormGroup.addControl('id', new FormControl(smsSettings.id, Validators.required));
          this.showSmsSettings(smsSettings);
        }
      });
  }
  checkSenderIdValidation() {
    if (this.smsSettingsFormGroup.value.senderId && this.smsSettingsFormGroup.value.gateway) {
      if (isNaN(this.smsSettingsFormGroup.value.senderId)) {
        this.smsSettingsFormGroup.get('senderId').setValidators([Validators.maxLength(11)]);
        this.smsSettingsFormGroup.get('senderId').updateValueAndValidity();
      } else {
        this.smsSettingsFormGroup.get('senderId').setValidators([Validators.maxLength(16)]);
        this.smsSettingsFormGroup.get('senderId').updateValueAndValidity();
      }
    } else {
      this.smsSettingsFormGroup.get('senderId').clearValidators();
      this.smsSettingsFormGroup.get('senderId').setErrors(null);
    }
  }
  showSmsSettings(smsSettings: any) {
    smsSettings.gateway = smsSettings.gateway == 10 ? 0 : 1;
    this.smsSettingsFormGroup.get('reservationActive').setValue(smsSettings.reservationActive);
    this.smsSettingsFormGroup.get('gateway').setValue(smsSettings.gateway);
    this.smsSettingsFormGroup.get('senderId').setValue(smsSettings.senderId);
    this.smsSettingsFormGroup.get('confirmationText').setValue(smsSettings.confirmationText);
    this.smsSettingsFormGroup.get('requestText').setValue(smsSettings.requestText);
  }
  saveSmsSettings() {
    this.checkSenderIdValidation();
    if (!this.smsSettingsFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.editObservableSmsSettings = this.reservationService
      .saveSmsSettings(this.smsSettingsFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.showSmsSettings(response);
          this.snackBar.open('Einstellungen erfolgreich aktualisiert', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        },
        err => {
          console.log('err', err);
          this.snackBar.open(err.error.msg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
  }
  changedSettings(isToggle?: string) {
    console.log('isToggle ====>', isToggle);
    if (isToggle) {
      this.saveSettings();
    } else {
      this.alert = 'Du hast ungespeicherte Änderungen.';
    }
  }
  changedReminderSMS() {
    if (
      this.generalSettings.remindGuestsTextMessage &&
      (!this.generalSettings.reminderSMSText || this.generalSettings.reminderSMSText.trim() == '')
    ) {
      this.reminderTextError = 'Bitte einen SMS Text für die Erinnerung eintragen.';
    } else if (this.generalSettings.reminderSMSText && this.generalSettings.reminderSMSText.length > 160) {
      this.reminderTextError = 'Maximal 160 Zeichen erlaubt.';
    } else {
      this.reminderTextError = '';
    }
  }
  saveShiftSettings(shiftId: any, type: any, value: any, clientId: any) {
    const settings = {
      shiftId,
      type,
      value,
      clientId
    };
    this.reservationService
      .saveShiftSettings(settings)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.showSmsSettings(response);
          this.snackBar.open('Vorlage erfolgreich aktualisiert', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        },
        err => {
          console.log('err', err);
          this.snackBar.open(err.error.msg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
  }
  addResMenuOrders() {
    const modalRef = this.modalService.open(AddResMenuOrders, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.resMenuOrders = this.resMenuOrders;
    modalRef.componentInstance.clientData = { ...this.activeClient };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.getAllResMenuOrders();
      const snackBarRef = this.snackBar.open('Order erfolgreich erstellt', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  editResMenuOrders(order: any, index: number) {
    const modalRef = this.modalService.open(AddResMenuOrders, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.resMenuOrders = this.resMenuOrders;
    modalRef.componentInstance.clientData = { ...this.activeClient };
    modalRef.componentInstance.editResMenuOrders = { ...order };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      if (receivedEntry) {
        this.getAllResMenuOrders();
        const snackBarRef = this.snackBar.open('Order erfolgreich gespeichert', 'Ok', {
          duration: 3000
        });
        modalRef.close();
      }
    });
  }

  deleteResMenuOrders(foreignId: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Order löschen';
    modalRef.componentInstance.message = `Bist du sicher, dass du diese Order löschen möchtest?`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.reservationService
            .deleteResMenuOrders(foreignId)
            .takeUntil(this.ngUnsubscribe)
            .subscribe((response: any) => {
              this.getAllResMenuOrders();
              const snackBarRef = this.snackBar.open('Order erfolgreich gelöscht', 'Ok', {
                duration: 3000
              });
            });
        }
      },
      () => {}
    );
  }

  addResTimeFilter() {
    const modalRef = this.modalService.open(AddTimeFilterComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.reservationService.addResTimeFilter(receivedEntry).then((response: any) => {
        this.resTimeFilter.push(response);
        const snackBarRef = this.snackBar.open('Erfolgreich.', 'Ok', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
        modalRef.close();
      });
    });
  }

  addStatus() {
    const modalRef = this.modalService.open(AddStatusComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.reservationService.addAndUpdateStatus(receivedEntry).then((response: any) => {
        this.getResStatus();
        const snackBarRef = this.snackBar.open('Erfolgreich.', 'Ok', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
        modalRef.close();
      });
    });
  }

  getResTimeFilter() {
    this.reservationService
      .getResTimeFilter()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((resTimeFilter: any) => {
        this.resTimeFilter = resTimeFilter;
      });
  }

  getResStatus() {
    this.reservationService
      .getResStatus()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((resStatusList: any) => {
        this.resStatusList = resStatusList;
        this.resStatusList = this.resStatusList.map((item: any) => {
          item['type'] = 'single';
          return { ...item, showActiveButton: true };
        });
        // this.resStatusList = this.resStatusList.concat(this.allStatus);
      });
  }

  editResStatus(data: any, index: any) {
    const modalRef = this.modalService.open(AddStatusComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.editStatusData = { ...data };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.reservationService.addAndUpdateStatus(receivedEntry).then((response: any) => {
        this.getResStatus();
        const snackBarRef = this.snackBar.open('Erfolgreich.', 'Ok', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
        modalRef.close();
      });
    });
  }

  deleteResStatus(id: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Zeitbereich löschen';
    modalRef.componentInstance.message = `Sind Sie sicher, dass Sie diesen Zeitraum löschen möchten?`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.reservationService
            .deleteResStatus(id)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(response => {
              if (response) {
                this.getResStatus();
                this.snackBar.open('Erfolgreich.', '', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
              }
            });
        }
      },
      () => {}
    );
  }

  editResTimeFilter(data: any, index: any) {
    const modalRef = this.modalService.open(AddTimeFilterComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.editTimeFilterData = { ...data };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.reservationService.editResTimeFilter(receivedEntry).then((response: any) => {
        this.resTimeFilter[index] = receivedEntry;
        const snackBarRef = this.snackBar.open('Erfolgreich.', 'Ok', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
        modalRef.close();
      });
    });
  }
  deleteResTimeFilter(id: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Zeitbereich löschen';
    modalRef.componentInstance.message = `Sind Sie sicher, dass Sie diesen Zeitraum löschen möchten?`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.reservationService
            .deleteResTimeFilter(id)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(response => {
              if (response) {
                this.resTimeFilter = this.resTimeFilter.filter((obj: any) => {
                  return obj.id !== id;
                });
                this.snackBar.open('Erfolgreich.', '', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
              }
            });
        }
      },
      () => {}
    );
  }

  getPhoneAssistantSettings() {
    this.reservationService
      .getPhoneAssistantSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((phoneAssistant: any) => {
        this.phoneAssistantSettings = phoneAssistant;
      });
  }

  getTimes() {
    this.times = [];
    let tStart = 0;
    const interval = 15;
    for (let i = 0; tStart < 24 * 60; i++) {
      const hh = Math.floor(tStart / 60); // getting hours of day in 0-24 format
      const mm = tStart % 60; // getting minutes of the hour in 0-55 format

      const timeObj = {
        time: ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2)
      };
      this.times.push(timeObj);
      tStart = tStart + interval;
    }
  }
}

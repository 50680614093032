import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Alert } from '../reservation-settings.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';
import { ReservationService } from '@app/reservation/reservation.service';
import moment from 'moment';
moment.locale('de');
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-add-res-series',
  templateUrl: './add-res-series.component.html',
  styleUrls: ['./add-res-series.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddResSeriesComponent implements OnInit, OnDestroy {
  @Input() public shifts: any;
  @Input() public editResTimeData: any; // only if editing
  @ViewChild('selectValue', { static: false }) selectValue: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  resSeriesFormGroup: FormGroup;
  allShifts: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  time1: NgbTimeStruct;
  time2: NgbTimeStruct;

  weekdays = [
    {
      day: 'Montag',
      isChecked: false,
      value: 'MO'
    },
    {
      day: 'Dienstag',
      isChecked: false,
      value: 'TU'
    },
    {
      day: 'Mittwoch',
      isChecked: false,
      value: 'WE'
    },
    {
      day: 'Donnerstag',
      isChecked: false,
      value: 'TH'
    },
    {
      day: 'Freitag',
      isChecked: false,
      value: 'FR'
    },
    {
      day: 'Samstag',
      isChecked: false,
      value: 'SA'
    },
    {
      day: 'Sonntag',
      isChecked: false,
      value: 'SU'
    }
  ];
  constructor(
    public activeModal: NgbActiveModal,
    public formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private reservationService: ReservationService
  ) {}

  ngOnInit() {
    this.resSeriesFormGroup = this.formBuilder.group(
      {
        startDate: [new Date(), Validators.required],
        endDate: [],
        startTime: ['', Validators.required],
        endTime: ['', Validators.required],
        shift: ['', Validators.required],
        frequency: ['never', Validators.required],
        dayEditOption: [this.editResTimeData && this.editResTimeData.blocked ? false : true]
      },
      { validator: this.dateLessThan('startDate', 'endDate') }
    );

    this.getAllShifts();
    if (this.editResTimeData) {
      this.time1 = {
        hour: this.editResTimeData.startDate.getHours(),
        minute: this.editResTimeData.startDate.getMinutes(),
        second: 0
      };
      this.time2 = {
        hour: this.editResTimeData.endDate.getHours(),
        minute: this.editResTimeData.endDate.getMinutes(),
        second: 0
      };
      this.resSeriesFormGroup.addControl('id', new FormControl(this.editResTimeData.id, Validators.required));
      this.resSeriesFormGroup
        .get('startDate')
        .setValue(
          this.editResTimeData.todayDate && this.editResTimeData.blocked
            ? this.editResTimeData.todayDate
            : this.editResTimeData.startDate
        );
      this.resSeriesFormGroup.get('startTime').setValue(this.time1);
      this.resSeriesFormGroup.get('endTime').setValue(this.time2);
      this.resSeriesFormGroup.get('shift').setValue(this.editResTimeData.shift);
      if (
        this.editResTimeData.recurrenceRuleFinal &&
        Object.keys(this.editResTimeData.recurrenceRuleFinal).length > 0
      ) {
        if (this.editResTimeData.recurrenceRuleFinal.BYDAY) {
          this.weekdays.map(item => {
            if (this.editResTimeData.recurrenceRuleFinal.BYDAY.includes(item.value)) {
              item.isChecked = true;
              return item;
            }
          });
        }
        this.resSeriesFormGroup.get('frequency').setValue(this.editResTimeData.recurrenceRuleFinal.FREQ.toLowerCase());
        if (this.editResTimeData.recurrenceRuleFinal.UNTIL !== undefined && !this.editResTimeData.blocked) {
          this.resSeriesFormGroup.get('endDate').setValue(moment(this.editResTimeData.recurrenceRuleFinal.UNTIL));
        }
      } else {
        this.resSeriesFormGroup.get('frequency').setValue('never');
      }
      this.changeEditOption();
    }
  }

  add() {
    const resSeriesFormValue = this.resSeriesFormGroup.getRawValue();
    if (!this.resSeriesFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben.', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    let weeklyDays: any = [];
    if (resSeriesFormValue.frequency == 'weekly') {
      weeklyDays = this.weekdays.filter(opt => opt.isChecked).map(opt => opt.value);
    }
    const formValue = { ...resSeriesFormValue, weeklyDays, oldStartTime: this.time1, oldEndTime: this.time2 };
    this.passEntry.emit(formValue);
  }

  clearInput() {
    this.resSeriesFormGroup.get('endDate').setValue(null);
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      const f = group.controls[from];
      const t = group.controls[to];
      if (t && t.value && moment(f.value) > moment(t.value)) {
        return {
          dates: 'Das End-Datum darf nicht vor dem Start-Datum liegen!'
        };
      }
      return {};
    };
  }
  getAllShifts() {
    if (this.shifts && this.shifts.length) {
      this.allShifts = this.shifts.map((item: any) => {
        // const description = item && item.description ? ' (' + item.description + ')' : '';
        // return item && item.name + description;
        const newItem = { ...item }; // Create a shallow copy of the item
        newItem.name = item.description ? item.description : item.name;
        return newItem;
      });
    }
  }
  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  selectAllDays() {
    const selectAllValue = this.selectValue.nativeElement.value;
    const checkedValue = selectAllValue == 'false' ? true : false;
    this.selectValue.nativeElement.value = checkedValue == true ? 'true' : 'false';
    return this.weekdays.map(item => {
      item.isChecked = checkedValue;
      return item;
    });
  }

  changeEditOption() {
    if (this.resSeriesFormGroup.value.dayEditOption) {
      this.resSeriesFormGroup.get('frequency').setValue('never');
      this.resSeriesFormGroup.get('frequency').disable();

      this.resSeriesFormGroup.get('startDate').setValue(this.editResTimeData.todayDate);
      this.resSeriesFormGroup.get('startDate').disable();

      this.resSeriesFormGroup.get('endDate').setValue(this.editResTimeData.todayDate);
      this.resSeriesFormGroup.get('endDate').disable();
      // this.resSeriesFormGroup.get('endDate').setValidators([Validators.required]);
    } else {
      this.resSeriesFormGroup.get('frequency').setValue(this.editResTimeData.recurrenceRuleFinal.FREQ.toLowerCase());
      this.resSeriesFormGroup.get('frequency').enable();

      this.resSeriesFormGroup
        .get('startDate')
        .setValue(
          this.editResTimeData.todayDate && this.editResTimeData.blocked
            ? this.editResTimeData.todayDate
            : this.editResTimeData.startDate
        );
      this.resSeriesFormGroup.get('startDate').enable();

      if (this.editResTimeData.recurrenceRuleFinal.UNTIL !== undefined && !this.editResTimeData.blocked) {
        this.resSeriesFormGroup.get('endDate').setValue(moment(this.editResTimeData.recurrenceRuleFinal.UNTIL));
      } else {
        this.resSeriesFormGroup.get('endDate').setValue('');
      }
      this.resSeriesFormGroup.get('endDate').enable();
      // this.resSeriesFormGroup.get('endDate').clearValidators();
      // this.resSeriesFormGroup.get('endDate').setErrors(null);
    }
    // this.resSeriesFormGroup.get('endDate').updateValueAndValidity();
  }
}
